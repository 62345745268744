import { parseAsStringEnum, useQueryStates } from "nuqs";

import { usePagination } from "@/shared/hooks";
import type { AdminSession } from "../models";
import { ADMIN_SESSION_STATUS } from "../models";

export const useSessionStatus = () => {
  const [tab, setTab] = useQueryStates(
    {
      status: parseAsStringEnum(
        Object.values(ADMIN_SESSION_STATUS),
      ).withDefault(ADMIN_SESSION_STATUS.UPCOMING),
    },
    {
      urlKeys: {
        status: "tab",
      },
    },
  );

  const setStatus = (status: string) => {
    if (
      Object.values(ADMIN_SESSION_STATUS).includes(
        status as AdminSession["status"],
      )
    ) {
      return setTab({ status: status as AdminSession["status"] });
    }
    return setTab(null);
  };

  const {
    actions: { resetPage },
  } = usePagination();

  const setPaginatedStatus = (newStatus: string) => {
    void setStatus(newStatus);
    resetPage();
  };

  return {
    status: tab.status,
    actions: { setPaginatedStatus, setStatus },
  };
};
