import type { ComponentPropsWithoutRef } from "react";
import type { Control } from "react-hook-form";
import { t } from "ttag";

import { Form, Select } from "@/ui";
import type { AdminSession } from "../models";
import { SESSION_CATEGORY } from "../models";

const options = Object.entries(SESSION_CATEGORY).map(([_, value]) => ({
  value: value,
  label: value,
}));

type SessionCategoryControl<T> =
  T extends Pick<AdminSession, "category"> ? Control<T, "category"> : never;

type SessionCategoryFieldProps<T extends Pick<AdminSession, "category">> =
  ComponentPropsWithoutRef<typeof Form.Item> & {
    control: SessionCategoryControl<T>;
  };

export const SessionCategoryField = <T extends Pick<AdminSession, "category">>({
  control,
  ...props
}: SessionCategoryFieldProps<T>) => {
  return (
    <Form.Field
      control={control}
      name="category"
      render={({ field }) => (
        <Form.Item {...props}>
          <Form.Label aria-required>{t`Category`}</Form.Label>
          <Form.Control>
            <Select options={options} {...field} />
          </Form.Control>
          <Form.Message compact={false} />
        </Form.Item>
      )}
    />
  );
};
