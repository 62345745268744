import type { ChevronProps } from "react-day-picker";

import { AltArrowLeftIcon, AltArrowRightIcon } from "@/ui/icons";
import { tw } from "@/utils";

export const NavButtons = ({ className, ...props }: ChevronProps) => {
  const Icon =
    props.orientation === "left" ? AltArrowLeftIcon : AltArrowRightIcon;

  return <Icon className={tw("size-6", className)} {...props} />;
};
