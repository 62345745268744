import type { ReactNode } from "react";
import type { VariantProps } from "tailwind-variants";
import { tv } from "tailwind-variants";

import { OnboardingProgressBar } from "./OnboardingProgressBar";

const onboardingLayoutVariance = tv({
  slots: {
    root: "flex h-dvh w-screen justify-center overflow-hidden",
    background: "fixed top-1/2 h-desktop w-desktop -translate-y-1/2",
    container:
      "relative z-10 flex h-full w-full max-w-desktop grow flex-col overflow-y-auto px-6 pt-10 sm:my-auto sm:max-h-desktop sm:items-center sm:gap-11 sm:px-10 sm:pt-20",
  },
  variants: {
    variant: {
      primary: {
        root: "bg-brown-02",
        container: "gap-9",
      },
      secondary: {
        root: "bg-nature-08",
        container: "relative gap-20 sm:gap-32",
      },
    },
  },
  defaultVariants: {
    variant: "primary",
  },
});

type OnboardingLayoutVariance = VariantProps<typeof onboardingLayoutVariance>;

type OnboardingLayoutProps = OnboardingLayoutVariance & {
  showProgressBar?: boolean;
  children: ReactNode;
  background?: ReactNode;
  className?: string;
};

export const OnboardingLayout = ({
  showProgressBar = true,
  children,
  background,
  variant,
  className,
}: OnboardingLayoutProps) => {
  const {
    root,
    background: backgroundSlot,
    container,
  } = onboardingLayoutVariance({ variant });

  return (
    <div className={root({ className })}>
      {background && <div className={backgroundSlot()}>{background}</div>}

      <div className={container()}>
        {showProgressBar && <OnboardingProgressBar variant={variant} />}

        {children}
      </div>
    </div>
  );
};
