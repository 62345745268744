import type { PropsWithChildren } from "react";
import { createContext, useContext } from "react";

import { useCreateSessionForm } from "../hooks";

interface CreateSessionContextProps {
  sessionForm: ReturnType<typeof useCreateSessionForm>;
}

export const CreateSessionContext =
  createContext<CreateSessionContextProps | null>(null);

export const CreateSessionContextProvider = ({
  children,
}: PropsWithChildren) => {
  const sessionForm = useCreateSessionForm();

  return (
    <CreateSessionContext.Provider value={{ sessionForm }}>
      {children}
    </CreateSessionContext.Provider>
  );
};

export const useCreateSessionContext = () => {
  const context = useContext(CreateSessionContext);

  if (!context) {
    throw new Error(
      "useCreateSessionContext must be used within a <CreateSessionContextProvider />",
    );
  }

  return context;
};
