import { format } from "date-fns";
import { generatePath, Link } from "react-router-dom";
import { t } from "ttag";

import { SESSION_COLOR_MAP, SESSION_ICON_MAP } from "@/domains/coaching/models";
import type { AdminSession, Session } from "@/domains/coaching/models";
import { ROUTES } from "@/router";
import { extractTimeFromDate } from "@/shared/utils";
import {
  ClockIcon,
  DetailCard,
  IconWrapper,
  PenIcon,
  TrashBin2Icon,
  UserCircleIcon,
  UsersGroupTwoRoundedIcon,
} from "@/ui";
import { PatientUpcomingCardActions } from "./PatientUpcomingActions";

type UpcomingSessionCardProps =
  | {
      admin: true;
      session: AdminSession;
      onDelete?: (session: AdminSession) => void;
      onSaveSpot?: never;
    }
  | {
      admin?: false;
      session: Session;
      onDelete?: never;
      onSaveSpot: (session: Session) => void;
    };

export const UpcomingSessionCard = ({
  admin,
  session,
  onDelete,
  onSaveSpot,
}: UpcomingSessionCardProps) => {
  return (
    <DetailCard.Root color={SESSION_COLOR_MAP[session.category]}>
      <DetailCard.Header>
        <IconWrapper size="sm">
          {SESSION_ICON_MAP[session.category]}
        </IconWrapper>
        <DetailCard.Title className="line-clamp-2">
          {session.title}
        </DetailCard.Title>
      </DetailCard.Header>

      <DetailCard.Content>
        <div className="mt-auto flex flex-wrap gap-2 sm:flex-nowrap">
          <DetailCard.Badge>
            {session.startAt ? format(session.startAt, "MM/dd/yy") : "-"}
          </DetailCard.Badge>
          <DetailCard.Badge variant="outline">
            {session.startAt
              ? extractTimeFromDate(session.startAt, "HH:mm")
              : "-"}
          </DetailCard.Badge>
        </div>

        <div className="flex flex-row gap-4">
          <div className="flex min-w-0 flex-1 items-center gap-1">
            <UserCircleIcon className="size-3.5" />
            <DetailCard.Label>{t`Coach`}</DetailCard.Label>
            <DetailCard.Description className="truncate">
              {session.coach.name}
            </DetailCard.Description>
          </div>

          <div className="flex items-center gap-1">
            <DetailCard.Description>{session.duration}</DetailCard.Description>
            <DetailCard.Label>{t`min`}</DetailCard.Label>
            <ClockIcon className="size-3.5" />
          </div>
        </div>
      </DetailCard.Content>

      <DetailCard.Separator />

      <DetailCard.Footer>
        {admin && (
          <>
            <div className="flex items-center gap-1">
              <UsersGroupTwoRoundedIcon className="size-3.5" />
              <DetailCard.Description>
                {t`${session.enrolledUsers} enrolled users`}
              </DetailCard.Description>
            </div>

            <div className="flex gap-1">
              <DetailCard.Button
                onClick={() => onDelete?.(session)}
                aria-label="Delete session"
              >
                <TrashBin2Icon className="size-3.5 text-red-08" />
              </DetailCard.Button>
              <DetailCard.Button asChild>
                <Link
                  to={generatePath(ROUTES.COACHING.EDIT_SESSION, {
                    sessionId: String(session.id),
                  })}
                >
                  <PenIcon className="size-3.5 text-nature-08" />
                </Link>
              </DetailCard.Button>
            </div>
          </>
        )}

        {!admin && (
          <PatientUpcomingCardActions
            session={session}
            onSaveSpot={() => onSaveSpot(session)}
          />
        )}
      </DetailCard.Footer>
    </DetailCard.Root>
  );
};
