import { format, parseISO } from "date-fns";

export const formatDateToISO = (date: Date) => {
  return format(date, "yyyy-MM-dd");
};

export const formatISO = (date: string, dateFormat = "MM/dd/yy") => {
  return format(parseISO(date), dateFormat);
};

export const extractTimeFromDate = (
  date: Date,
  timeFormat = "HH:mm:ss",
): string => {
  return format(date, timeFormat);
};

export const createDateFromTime = (time: string, baseDate: Date) => {
  const [hours, minutes, seconds] = time.split(":").map(Number);

  return new Date(
    baseDate.getFullYear(),
    baseDate.getMonth(),
    baseDate.getDate(),
    hours,
    minutes,
    seconds,
  );
};
