import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

import type { AdminSession } from "../models";
import { getAdminSessionSchema } from "../models";

type FormValues = Omit<AdminSession, "coach">;

export const useEditSessionForm = (values?: FormValues) => {
  return useForm<FormValues>({
    resolver: zodResolver(getAdminSessionSchema().omit({ coach: true })),
    values,
  });
};
