import { useState } from "react";
import { t } from "ttag";

import { ErrorState } from "@/components";
import {
  CardsGrid,
  SubscribeSessionModal,
  UpcomingSessionCard,
} from "@/domains/coaching/components";
import {
  useSessionCategory,
  useSessions,
  useSessionStatus,
} from "@/domains/coaching/hooks";
import type { Session } from "@/domains/coaching/models";
import { SESSION_ICON_MAP } from "@/domains/coaching/models";
import { useBoolean } from "@/hooks";
import { usePagination } from "@/shared/hooks";
import {
  CardPrimitive,
  DetailCardSkeleton,
  EmptyState,
  Pagination,
} from "@/ui";

export const UpcomingSessions = () => {
  const { category } = useSessionCategory();
  const { status } = useSessionStatus();
  const {
    page,
    pageSize,
    actions: { changePage },
  } = usePagination();

  const { data, isLoading, isSuccess, isError } = useSessions({
    filter: { category, status },
    pagination: { page, pageSize },
  });
  const sessions = data?.data ?? [];
  const pagination = data?.pagination;

  const {
    value: showSubscribeModal,
    setTrue: openSubscribeModal,
    setFalse: closeSubscribeModal,
  } = useBoolean(false);

  const [sessionToSubscribe, setSessionToSubscribe] = useState<Session>();

  const handleSaveSpot = (session: Session) => {
    setSessionToSubscribe(session);
    openSubscribeModal();
  };

  const handleCloseSubscribeModal = () => {
    closeSubscribeModal();
  };

  const categoryText = category ?? "";

  return (
    <CardPrimitive.Root className="h-full gap-4 bg-brown-02">
      {isLoading && (
        <CardsGrid>
          {Array.from({ length: 4 }).map((_, index) => (
            <DetailCardSkeleton key={`detail-card-skeleton-${index}`} />
          ))}
        </CardsGrid>
      )}

      {Boolean(isSuccess && sessions.length) && (
        <CardsGrid>
          {sessions.map((session) => (
            <UpcomingSessionCard
              key={session.id}
              session={session}
              onSaveSpot={handleSaveSpot}
            />
          ))}
        </CardsGrid>
      )}

      {isSuccess && !sessions.length && (
        <EmptyState.Root className="justify-self-center border-transparent">
          <EmptyState.Icon>
            {category && SESSION_ICON_MAP[category]}
          </EmptyState.Icon>
          <EmptyState.Description>
            {t`There are no upcoming ${categoryText} sessions yet`}
          </EmptyState.Description>
        </EmptyState.Root>
      )}

      {isError && (
        <ErrorState className="justify-self-center border-transparent" />
      )}

      {pagination && pagination.totalPages > 1 && (
        <div className="flex justify-center border-t border-brown-04 pt-4 md:justify-end">
          <Pagination {...pagination} onPageChange={changePage} />
        </div>
      )}

      <SubscribeSessionModal
        show={Boolean(showSubscribeModal && sessionToSubscribe)}
        onClose={handleCloseSubscribeModal}
        session={sessionToSubscribe}
      />
    </CardPrimitive.Root>
  );
};
