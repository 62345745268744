import type { ServiceResponse } from "@/api";
import { privateAPI } from "@/api";
import type { Coach } from "../models";

export const getCoaches = async () => {
  const { data } = await privateAPI.get<ServiceResponse<Coach[]>>(
    `/coaching-admin/coaches`,
  );

  return data.data;
};
