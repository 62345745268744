import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { UseFormSetError } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import { ROUTES } from "@/router";
import { errorToast, useToastStore } from "@/ui";
import { handleAxiosFieldErrors } from "@/utils";
import { coachingQueriesStore, updateSession } from "../api";
import type { AdminSession } from "../models";

export const useUpdateSession = (setError?: UseFormSetError<AdminSession>) => {
  const pushToast = useToastStore((state) => state.pushToast);

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateSession,
    onSuccess: () => {
      void pushToast({
        type: "success",
        title: t`Success`,
        message: t`Session successfully updated!`,
      });

      void queryClient.invalidateQueries({
        queryKey:
          coachingQueriesStore.coaching.admin._ctx.session._ctx.list._def,
      });

      navigate(ROUTES.COACHING.OVERVIEW);
    },
    onError: (error) => {
      errorToast(error);
      if (setError) {
        handleAxiosFieldErrors(error, setError);
      }
    },
  });
};
