import { useQuery } from "@tanstack/react-query";

import { coachingQueriesStore } from "../api";

export const useCoachOptions = () => {
  return useQuery({
    ...coachingQueriesStore.coaching.admin._ctx.coach._ctx.list,
    select: (data) =>
      data.map((coach) => ({ label: coach.name, value: coach.id })),
  });
};
