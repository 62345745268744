import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

import { getBirthInfoSchema } from "@/shared.schemas";
import type { BirthData, OnboardingBirthInfoFormValues } from "@/shared.types";
import { parseDOB } from "@/utils";

export const useBirthInfoForm = (birthData?: BirthData | null) => {
  return useForm<OnboardingBirthInfoFormValues>({
    resolver: zodResolver(getBirthInfoSchema()),
    defaultValues: {
      gender: birthData?.gender,
      dateOfBirth: birthData?.dateOfBirth && parseDOB(birthData.dateOfBirth),
    },
  });
};
