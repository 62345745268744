import type { QueryClient } from "@tanstack/react-query";

import type { AvailableLanguage } from "@/shared";
import type { Patient, PatientFormValues } from "@/shared.types";
import type { ServiceResponse } from "./api.types";
import { privateAPI } from "./axios";
import { PORTAL_DOMAIN } from "./portal";

export const PATIENT_DOMAIN = "patient";

export const getPatientQuery = () => ({
  queryKey: [PATIENT_DOMAIN, "getPatientQuery"],
  queryFn: async () => {
    const { data } =
      await privateAPI.get<ServiceResponse<Patient>>(`/patients`);

    return data.data;
  },
  staleTime: Infinity,
});

const parsePatientFormValues = (values: PatientFormValues) => {
  const {
    email,
    firstName,
    lastName,
    dateOfBirth,
    gender,
    phoneNumber,

    city,
    state,
    zip,
    line1,
    line2,

    timezone,

    cardFrontImage,
    cardBackImage,
    cardFrontId,
    cardBackId,
  } = values;

  return {
    email,
    firstName,
    lastName,
    dateOfBirth,
    gender,
    phoneNumber,
    location: {
      city,
      state,
      zip,
      line1,
      line2,
    },
    timezone,
    policy: {
      cardFrontImage,
      cardBackImage,
      cardFrontId,
      cardBackId,
    },
  };
};

const invalidatesWithPortalUserQuery = (queryClient: QueryClient) => {
  void queryClient.invalidateQueries({ queryKey: [PATIENT_DOMAIN] });
  void queryClient.invalidateQueries({
    queryKey: [PORTAL_DOMAIN],
  });
};

export const updatePatient = {
  mutation: async (params: PatientFormValues) => {
    const { data } = await privateAPI.put<ServiceResponse<Patient>>(
      "/patients",
      parsePatientFormValues(params),
    );

    return data.data;
  },
  invalidates: invalidatesWithPortalUserQuery,
};

export const updatePatientLanguagePreference = {
  mutation: async (language: AvailableLanguage) => {
    const { data } = await privateAPI.put<
      ServiceResponse<{ message: string; language: AvailableLanguage }>
    >("/patients/language", { language });

    return data.data;
  },
  invalidates: invalidatesWithPortalUserQuery,
};

interface UploadInsuranceCardParams {
  insuranceCardFront?: File;
  insuranceCardBack?: File;
}

export const uploadInsuranceCard = {
  mutation: async (insuranceCard: UploadInsuranceCardParams) => {
    const { data } = await privateAPI.post<
      ServiceResponse<{ cardFrontUrl?: string; cardBackUrl?: string }>
    >(
      "/patients/upload-insurance-card",
      { ...insuranceCard },
      { headers: { "Content-Type": "multipart/form-data" } },
    );
    return data.data;
  },
  invalidates: (queryClient: QueryClient) => {
    void queryClient.invalidateQueries({ queryKey: [PATIENT_DOMAIN] });
  },
};
