import type { DropdownProps } from "react-day-picker";
import { useDayPicker } from "react-day-picker";

import { Select } from "@/ui/form";

export const YearSelect = ({ value, options }: DropdownProps) => {
  const { months, goToMonth } = useDayPicker();

  return (
    <Select
      value={value as number}
      options={options}
      onChange={(year) => {
        const currentDate = months[0]?.date ?? new Date();
        currentDate.setFullYear(year);
        goToMonth(currentDate);
      }}
    />
  );
};
