import { useEffect, useState } from "react";
import type { DPDatesConfig, DPYearsConfig } from "@rehookify/datepicker";
import { useDatePicker } from "@rehookify/datepicker";

import type { ISODate } from "@/shared.types";
import { useAppSettingsStore } from "@/stores";
import { icons, IconWrapper, Skeleton } from "@/ui/common";
import { formatToBackendDate, parseBackendDate, tw } from "@/utils";
import { MonthSelect } from "./MonthSelect";
import { YearSelect } from "./YearSelect";

interface DatePickerProps {
  value: ISODate | null;
  availableDays?: ISODate[];
  config?: { dates?: Partial<DPDatesConfig>; years?: Partial<DPYearsConfig> };
  isLoading?: boolean;
  onChange: (value: ISODate | null) => void;
  onOffsetDateChange?: (value: ISODate) => void;
}

export const LegacyCalendar = ({
  value,
  availableDays,
  config,
  isLoading,
  onChange,
  onOffsetDateChange,
}: DatePickerProps) => {
  const [offsetDate, setOffsetDate] = useState(new Date());

  useEffect(() => {
    if (value) {
      setOffsetDate(parseBackendDate(value));
    }
  }, [value]);

  const onOffsetChange = (date: Date) => {
    const updatedOffsetDate = new Date(date.getFullYear(), date.getMonth(), 1);
    setOffsetDate(updatedOffsetDate);
    onOffsetDateChange?.(formatToBackendDate(updatedOffsetDate));
  };

  const selectedDates = !value?.length ? [] : [parseBackendDate(value)];

  const onDatesChange = (dates: Date[]) => {
    const updatedValue = dates[0] ? formatToBackendDate(dates[0]) : null;
    onChange(updatedValue);
    onOffsetChange(dates[0] ?? offsetDate);
  };

  const locale = useAppSettingsStore((state) => state.language);

  const {
    data: { weekDays, calendars, years, months },
    propGetters: { dayButton, addOffset, subtractOffset },
  } = useDatePicker({
    locale: { locale },
    selectedDates,
    onDatesChange,
    offsetDate,
    onOffsetChange,
    ...config,
  });
  const days = calendars[0]?.days;

  const handleNewYear = (year: number) => {
    const updatedOffsetDate = new Date(year, offsetDate.getMonth());
    onOffsetChange(updatedOffsetDate);
  };
  const handleNewMonth = (month: number) => {
    const updatedOffsetDate = new Date(offsetDate.getFullYear(), month);
    onOffsetChange(updatedOffsetDate);
  };

  const availableDates = availableDays?.map((day) => parseBackendDate(day));
  const formattedDays = !availableDays
    ? days
    : days?.map((dpDay) => ({
        ...dpDay,
        disabled: !availableDates?.some(
          (date) => date.getTime() === dpDay.$date.getTime(),
        ),
      }));

  return (
    <div className="relative h-full overflow-hidden rounded-md bg-salmon-01 p-5 shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2">
      {isLoading && <div className="absolute inset-0 z-10 h-full w-full" />}
      <header className={tw("flex flex-col gap-2", isLoading && "opacity-50")}>
        <div className="flex items-center">
          <button
            type="button"
            className="flex items-center justify-center rounded-full focus:border-salmon-09 focus:outline-salmon-09 enabled:hover:stroke-2 disabled:cursor-not-allowed disabled:opacity-60"
            {...subtractOffset({ months: 1 })}
            aria-label="Previous month"
          >
            <IconWrapper size="md">
              <icons.ChevronLeft />
            </IconWrapper>
          </button>
          <div className="flex grow items-center justify-center gap-2">
            <MonthSelect
              months={months}
              month={offsetDate.getMonth()}
              onChange={handleNewMonth}
            />
            <YearSelect
              years={years}
              year={offsetDate.getFullYear()}
              onChange={handleNewYear}
            />
          </div>
          <button
            type="button"
            className="flex items-center justify-center rounded-full focus:border-salmon-09 focus:outline-salmon-09 enabled:hover:stroke-2 disabled:cursor-not-allowed disabled:opacity-60"
            {...addOffset({ months: 1 })}
            aria-label="Next month"
          >
            <IconWrapper size="md">
              <icons.ChevronRight />
            </IconWrapper>
          </button>
        </div>
        <ul className="grid grid-cols-7 py-4 text-nature-09">
          {weekDays.map((day) => (
            <li
              key={`${offsetDate.getMonth()}-${day}`}
              className="flex items-center justify-center text-xs"
            >
              {day}
            </li>
          ))}
        </ul>
      </header>
      <ul className={tw("grid grid-cols-7 gap-0.5", isLoading && "opacity-50")}>
        {formattedDays?.map((dpDay) => (
          <li
            key={dpDay.$date.toDateString()}
            className="flex items-center justify-center"
          >
            {isLoading ? (
              <div className="flex h-11 w-11 items-center justify-center">
                <Skeleton className="h-9 w-9 rounded-md" />
              </div>
            ) : (
              <button
                type="button"
                {...dayButton(dpDay)}
                className={tw(
                  "h-11 w-11 rounded-full border border-transparent text-sm font-semibold text-brown-10 hover:border-nature-07",
                  !dpDay.inCurrentMonth && "text-nature-03",
                  dpDay.selected && "bg-nature-07 text-nature-01",
                  dpDay.now && "border-salmon-06",
                  dpDay.disabled && [
                    "cursor-not-allowed",
                    !availableDates && "text-brown-02 hover:border-none",
                  ],
                  !!availableDates && [
                    !dpDay.disabled && "bg-brown-02",
                    dpDay.disabled && !dpDay.now && "hover:border-transparent",
                    dpDay.selected && "bg-nature-07 text-nature-01",
                    dpDay.now && "border-salmon-06 hover:border-salmon-06",
                  ],
                )}
              >
                {dpDay.day}
              </button>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};
