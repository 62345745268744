import type { ComponentPropsWithoutRef, ElementRef } from "react";
import { createContext, forwardRef, useContext } from "react";
import * as TabsPrimitive from "@radix-ui/react-tabs";
import type { VariantProps } from "tailwind-variants";
import { tv } from "tailwind-variants";

const tabVariant = tv({
  slots: {
    root: "",
    list: "inline-flex w-full items-end justify-center text-brown-09",
    trigger:
      "group inline-flex flex-1 items-center justify-center whitespace-nowrap border-b-2 border-brown-05 px-3 py-2 text-sm font-medium transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-nature-04 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:border-nature-10 data-[state=active]:font-semibold data-[state=active]:text-nature-10",
    content:
      "grow flex-col ring-offset-nature-03 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-nature-04 focus-visible:ring-offset-2",
  },
  variants: {
    variant: {
      secondary: {
        root: "flex grow flex-col gap-6",
        list: "items-center rounded-lg bg-brown-03 p-1 text-brown-07",
        trigger:
          "h-full rounded-md border-b-0 px-4 py-3 opacity-90 disabled:opacity-50 data-[state=active]:bg-salmon-01 data-[state=active]:font-bold data-[state=active]:text-brown-09 data-[state=active]:opacity-100 md:px-6",
        content: "ring-offset-green-100",
      },
    },
  },
});

const { root, list, trigger, content } = tabVariant();

type TabVariant = VariantProps<typeof tabVariant>;
type TabRootProps = ComponentPropsWithoutRef<typeof TabsPrimitive.Root> &
  TabVariant;

const TabVariantContext = createContext<TabVariant["variant"]>(undefined);

const Root = forwardRef<ElementRef<typeof TabsPrimitive.Root>, TabRootProps>(
  ({ className, variant, ...props }, ref) => {
    return (
      <TabVariantContext.Provider value={variant}>
        <TabsPrimitive.Root
          ref={ref}
          {...props}
          className={root({ variant, className })}
        />
      </TabVariantContext.Provider>
    );
  },
);
Root.displayName = "Tabs.Root";

const List = forwardRef<
  ElementRef<typeof TabsPrimitive.List>,
  ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => {
  const variant = useContext(TabVariantContext);
  return (
    <TabsPrimitive.List
      ref={ref}
      className={list({ variant, className })}
      {...props}
    />
  );
});
List.displayName = "Tabs.List";

const Trigger = forwardRef<
  ElementRef<typeof TabsPrimitive.Trigger>,
  ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => {
  const variant = useContext(TabVariantContext);
  return (
    <TabsPrimitive.Trigger
      ref={ref}
      className={trigger({ variant, className })}
      {...props}
    />
  );
});
Trigger.displayName = "Tabs.Trigger";

const Content = forwardRef<
  ElementRef<typeof TabsPrimitive.Content>,
  ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => {
  const variant = useContext(TabVariantContext);
  return (
    <TabsPrimitive.Content
      ref={ref}
      className={content({ variant, className })}
      {...props}
    />
  );
});
Content.displayName = "Tabs.Content";

export { Root, List, Trigger, Content };
