import { Link } from "react-router-dom";
import { t } from "ttag";

import type { InactiveSubscriptionStatus } from "@/domains/billing/models";
import { INACTIVE_STATUS } from "@/domains/billing/models";
import { ROUTES } from "@/router";
import { Alert, Button, DollarMinimalisticIcon, IconWrapper } from "@/ui";
import { isInactiveSubscription } from "@/utils";

interface InactiveSubscriptionAlertProps {
  subscriptionStatus: InactiveSubscriptionStatus;
}

export const InactiveSubscriptionAlert = ({
  subscriptionStatus,
}: InactiveSubscriptionAlertProps) => {
  if (!isInactiveSubscription(subscriptionStatus)) {
    return null;
  }

  const route =
    subscriptionStatus === INACTIVE_STATUS.PAYMENT_FAILED
      ? ROUTES.BILLING.OVERVIEW
      : ROUTES.MESSAGES;

  return (
    <Alert type="warning" className="border-yellow-05">
      <div className="flex flex-col gap-4 lg:flex-row lg:gap-2">
        <div className="grow">
          <h6 className="font-bold text-yellow-10">
            {subscriptionStatus === INACTIVE_STATUS.PAYMENT_FAILED
              ? t`Payment failed`
              : t`Your subscription has been cancelled.`}
          </h6>
          <p>
            {subscriptionStatus === INACTIVE_STATUS.PAYMENT_FAILED
              ? t`There was an issue processing your payment. Please check your details and try again, or use a different payment method.`
              : t`Don't worry, we're here to assist you, please check your messages.`}
          </p>
        </div>

        <Button asChild className="-ml-6 sm:ml-0 sm:self-start lg:self-center">
          <Link to={route}>
            <IconWrapper as="span" size="sm">
              <DollarMinimalisticIcon />
            </IconWrapper>
            {subscriptionStatus === INACTIVE_STATUS.PAYMENT_FAILED
              ? t`Update payment`
              : t`Contact customer support`}
          </Link>
        </Button>
      </div>
    </Alert>
  );
};
