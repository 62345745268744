import { format } from "date-fns";
import { generatePath, Link } from "react-router-dom";
import { t } from "ttag";

import { FullScreenLoader } from "@/components";
import { SESSION_COLOR_MAP, SESSION_ICON_MAP } from "@/domains/coaching/models";
import type { AdminSession } from "@/domains/coaching/models";
import { ROUTES } from "@/router";
import { extractTimeFromDate } from "@/shared/utils";
import {
  CheckCircleIcon,
  ClockIcon,
  DetailCard,
  DropdownMenu,
  IconWrapper,
  MenuDotsIcons,
  PenIcon,
  TrashBinIcon,
  UserCircleIcon,
  UsersGroupTwoRoundedIcon,
} from "@/ui";
import { useUpdateSession } from "../../hooks";

interface DraftSessionCardProps {
  session: AdminSession;
  onDelete: (session: AdminSession) => void;
}

export const DraftSessionCard = ({
  session,
  onDelete,
}: DraftSessionCardProps) => {
  const updateSessionMutation = useUpdateSession();

  const handlePublish = () =>
    updateSessionMutation.mutate({ ...session, published: true });

  return (
    <DetailCard.Root color={SESSION_COLOR_MAP[session.category]}>
      <DetailCard.Header>
        <IconWrapper size="sm">
          {SESSION_ICON_MAP[session.category]}
        </IconWrapper>
        <DetailCard.Title className="line-clamp-2">
          {session.title}
        </DetailCard.Title>
      </DetailCard.Header>

      <DetailCard.Content>
        <div className="mt-auto flex flex-wrap gap-2 sm:flex-nowrap">
          <DetailCard.Badge>
            {session.startAt ? format(session.startAt, "MM/dd/yy") : "-"}
          </DetailCard.Badge>
          <DetailCard.Badge variant="outline">
            {session.startAt
              ? extractTimeFromDate(session.startAt, "HH:mm")
              : "-"}
          </DetailCard.Badge>
        </div>

        <div className="flex flex-row gap-4">
          <div className="flex min-w-0 flex-1 items-center gap-1">
            <UserCircleIcon className="size-3.5" />
            <DetailCard.Label>{t`Coach`}</DetailCard.Label>
            <DetailCard.Description className="truncate">
              {session.coach.name}
            </DetailCard.Description>
          </div>

          <div className="flex items-center gap-1">
            <DetailCard.Description>{session.duration}</DetailCard.Description>
            <DetailCard.Label>{t`min`}</DetailCard.Label>
            <ClockIcon className="size-3.5" />
          </div>
        </div>
      </DetailCard.Content>

      <DetailCard.Separator />

      <DetailCard.Footer>
        <div className="flex items-center gap-1">
          <UsersGroupTwoRoundedIcon className="size-3.5" />
          <DetailCard.Description>{t`Enrolled users will appear here`}</DetailCard.Description>
        </div>

        <div className="flex gap-1">
          <DropdownMenu.Root modal={false}>
            <DropdownMenu.Trigger
              asChild
              className="data-[state=open]:bg-salmon-02"
            >
              <DetailCard.Button>
                <MenuDotsIcons className="size-3.5 rotate-90" />
              </DetailCard.Button>
            </DropdownMenu.Trigger>

            <DropdownMenu.Content align="end">
              <Link
                to={generatePath(ROUTES.COACHING.EDIT_SESSION, {
                  sessionId: String(session.id),
                })}
              >
                <DropdownMenu.Item className="gap-2">
                  <PenIcon className="size-3.5 text-nature-08" />
                  {t`Edit`}
                </DropdownMenu.Item>
              </Link>

              <DropdownMenu.Separator />

              <DropdownMenu.Item onClick={handlePublish} className="gap-2">
                <IconWrapper size="sm" className="text-brown-08">
                  <CheckCircleIcon />
                </IconWrapper>
                {t`Publish`}
              </DropdownMenu.Item>

              <DropdownMenu.Separator />

              <DropdownMenu.Item
                onClick={() => onDelete?.(session)}
                className="gap-2"
              >
                <IconWrapper size="sm" className="text-brown-08">
                  <TrashBinIcon className="text-red-08" />
                </IconWrapper>
                {t`Delete`}
              </DropdownMenu.Item>
            </DropdownMenu.Content>
          </DropdownMenu.Root>
        </div>
      </DetailCard.Footer>

      {updateSessionMutation.isPending && <FullScreenLoader />}
    </DetailCard.Root>
  );
};
