import { useQuery } from "@tanstack/react-query";

import type { getAdminSessions } from "../api";
import { coachingQueriesStore } from "../api";

export const useAdminSessions = (
  params: Parameters<typeof getAdminSessions>[0],
) => {
  return useQuery(
    coachingQueriesStore.coaching.admin._ctx.session._ctx.list(params),
  );
};
