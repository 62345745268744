import { SessionCategories } from "@/domains/coaching/components";
import { useSessionStatus } from "@/domains/coaching/hooks";
import {
  ADMIN_SESSION_STATUS,
  getSessionStatusLabel,
} from "@/domains/coaching/models";
import { Tabs } from "@/ui";
import { DraftSessions } from "./DraftSessions";
import { PastSessions } from "./PastSessions";
import { UpcomingSessions } from "./UpcomingSessions";

export const CoachingLabTabs = () => {
  const {
    status,
    actions: { setPaginatedStatus },
  } = useSessionStatus();

  return (
    <Tabs.Root
      value={status}
      onValueChange={setPaginatedStatus}
      className="flex grow flex-col gap-6 pb-12"
    >
      <Tabs.List>
        <Tabs.Trigger
          key={ADMIN_SESSION_STATUS.UPCOMING}
          value={ADMIN_SESSION_STATUS.UPCOMING}
        >
          {getSessionStatusLabel(ADMIN_SESSION_STATUS.UPCOMING)}
        </Tabs.Trigger>

        <Tabs.Trigger
          key={ADMIN_SESSION_STATUS.DRAFT}
          value={ADMIN_SESSION_STATUS.DRAFT}
        >
          {getSessionStatusLabel(ADMIN_SESSION_STATUS.DRAFT)}
        </Tabs.Trigger>

        <Tabs.Trigger
          key={ADMIN_SESSION_STATUS.PAST}
          value={ADMIN_SESSION_STATUS.PAST}
        >
          {getSessionStatusLabel(ADMIN_SESSION_STATUS.PAST)}
        </Tabs.Trigger>
      </Tabs.List>

      <SessionCategories />

      <Tabs.Content value={ADMIN_SESSION_STATUS.UPCOMING}>
        <UpcomingSessions />
      </Tabs.Content>

      <Tabs.Content value={ADMIN_SESSION_STATUS.DRAFT}>
        <DraftSessions />
      </Tabs.Content>

      <Tabs.Content value={ADMIN_SESSION_STATUS.PAST}>
        <PastSessions />
      </Tabs.Content>
    </Tabs.Root>
  );
};
