import { t } from "ttag";

import { FullScreenLoader } from "@/components";
import { BLOCK_SUBSCRIPTION_REASON } from "@/domains/coaching/models";
import type { Session } from "@/domains/coaching/models";
import { Button, DetailCard, UserCrossIcon, UserHeartRoundedIcon } from "@/ui";
import { useUnsubscribeToSession } from "../../hooks";

interface PatientUpcomingCardActionsProps {
  session: Session;
  onSaveSpot: () => void;
}

export const PatientUpcomingCardActions = ({
  session,
  onSaveSpot,
}: PatientUpcomingCardActionsProps) => {
  const unsubscribeToSessionMutation = useUnsubscribeToSession();

  if (session.isSubscribable) {
    return (
      <Button onClick={onSaveSpot} size="xs" color="brown" className="w-full">
        {t`Save your spot`}
        <UserHeartRoundedIcon className="size-3" />
      </Button>
    );
  }

  if (
    session.reasonToBlockSubscription ===
    BLOCK_SUBSCRIPTION_REASON.ALREADY_SUBSCRIBED
  ) {
    return (
      <div className="flex grow items-center gap-2">
        <Button
          onClick={() => unsubscribeToSessionMutation.mutate(session.id)}
          size="xs"
          variant="tertiary"
          className="gap-1 px-0"
        >
          {t`Unregister`}
          <UserCrossIcon className="size-3" />
        </Button>

        <DetailCard.Description className="grow py-1 text-right text-brown-06">
          {t`Already registered!`}
        </DetailCard.Description>
        {unsubscribeToSessionMutation.isPending && <FullScreenLoader />}
      </div>
    );
  }

  return (
    <DetailCard.Description className="mx-auto inline-flex items-center gap-1 border-y border-transparent py-1 text-brown-06">
      {t`No spots left!`}
      <UserHeartRoundedIcon className="size-3" />
    </DetailCard.Description>
  );
};
