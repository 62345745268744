import { useFormContext } from "react-hook-form";
import { t } from "ttag";

import { PLAN_DURATION } from "@/domains/billing/models";
import { Tabs } from "@/ui";
import {
  CurrentRequestAlert,
  PortalSubscriptionPlans,
  TabList,
} from "./components";

export const ChangeSubscriptionContent = () => {
  const { reset } = useFormContext();

  return (
    <Tabs.Root
      defaultValue={PLAN_DURATION.MONTHLY.TEXT_VALUE}
      onValueChange={() => reset()}
      variant="secondary"
    >
      <TabList />

      <CurrentRequestAlert />

      <div className="grow pt-0 lg:pb-6">
        <Tabs.Content
          value={PLAN_DURATION.MONTHLY.TEXT_VALUE}
          className="flex flex-col gap-3"
        >
          <p className="-mt-3 text-center text-sm lg:hidden">{t`Get a 1 month supply`}</p>

          <PortalSubscriptionPlans
            durationInMonths={PLAN_DURATION.MONTHLY.VALUE}
          />
        </Tabs.Content>

        <Tabs.Content
          value={PLAN_DURATION.QUARTERLY.TEXT_VALUE}
          className="flex flex-col gap-3"
        >
          <p className="-mt-3 text-center text-sm lg:hidden">
            {t`Cover all 3 months of doses with a single order`}
          </p>

          <PortalSubscriptionPlans
            durationInMonths={PLAN_DURATION.QUARTERLY.VALUE}
          />
        </Tabs.Content>
      </div>
    </Tabs.Root>
  );
};
