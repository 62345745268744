import { PLAN_DURATION } from "@/domains/billing/models";
import type { AppointmentRequiredState } from "@/shared";
import { APPOINTMENT_REQUIRED_STATES } from "@/shared";
import { SENIOR_AGE_THRESHOLD } from "@/shared.constants";
import { calculateAge, formatDOB } from "@/utils";
import { useEmbeddablesForms } from "../context";

export const usePatientConditions = () => {
  const { mainInfoFormValues, patient, patientSubscription } =
    useEmbeddablesForms();

  const userAge = mainInfoFormValues?.dateOfBirth
    ? calculateAge(formatDOB(mainInfoFormValues.dateOfBirth))
    : 0;
  const state = patient.location?.state;

  const isOverAge = userAge >= SENIOR_AGE_THRESHOLD;

  const hasAppointmentRequiredState =
    !state ||
    APPOINTMENT_REQUIRED_STATES.includes(state as AppointmentRequiredState);

  const hasBundleSubscription =
    patientSubscription.currentPeriodPlan?.durationInMonths !==
    PLAN_DURATION.MONTHLY.VALUE;

  return { isOverAge, hasAppointmentRequiredState, hasBundleSubscription };
};
