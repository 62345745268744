import { Navigate, Route, Routes } from "react-router-dom";

import { Messages } from "@/screens";
import { ROUTES } from "../constants";
import { PortalRoutesWrapper } from "./PortalRoutesWrapper";

export const CoachingUserRouter = () => {
  return (
    <Routes>
      <Route element={<PortalRoutesWrapper />}>
        <Route path={ROUTES.MESSAGES} element={<Messages />} />
      </Route>

      <Route path="*" element={<Navigate replace to={ROUTES.BASE} />} />
    </Routes>
  );
};
