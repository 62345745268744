import type { ComponentPropsWithoutRef, ElementRef } from "react";
import { forwardRef } from "react";
import * as AccordionPrimitive from "@radix-ui/react-accordion";
import { tv } from "tailwind-variants";

const accordionVariants = tv({
  slots: {
    item: "w-full",
    trigger: "flex items-center",
    content:
      "w-full overflow-hidden data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down",
  },
});

const { item, trigger, content } = accordionVariants();

const Root = AccordionPrimitive.Root;
Root.displayName = "Accordion.Root";

const Item = forwardRef<
  ElementRef<typeof AccordionPrimitive.Item>,
  ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item
    ref={ref}
    className={item({ className })}
    {...props}
  />
));
Item.displayName = "Accordion.Item";

const Trigger = forwardRef<
  ElementRef<typeof AccordionPrimitive.Trigger>,
  ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Trigger
    ref={ref}
    className={trigger({ className })}
    {...props}
  />
));
Trigger.displayName = "Accordion.Trigger";

const Content = forwardRef<
  ElementRef<typeof AccordionPrimitive.Content>,
  ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className={content({ className })}
    {...props}
  />
));
Content.displayName = "Accordion.Content";

export const Accordion = {
  Root,
  Item,
  Trigger,
  Content,
};
