import { t } from "ttag";
import { z } from "zod";

import {
  ADMIN_SESSION_STATUS,
  BLOCK_SUBSCRIPTION_REASON,
  SESSION_CATEGORY,
  SESSION_TITLE_MAX_LENGTH,
} from "./constants";

export const getCoachSchema = () =>
  z.object({
    id: z.number({ required_error: t`Coach is required` }),
    name: z.string({ required_error: t`Coach name is required` }),
    email: z.string({ required_error: t`Coach email is required` }).email(),
  });

export const getCreateSessionSchema = () =>
  z.object({
    published: z.boolean(),
    category: z.nativeEnum(SESSION_CATEGORY, {
      message: t`Category is required`,
    }),
    title: z
      .string()
      .min(1, { message: t`Title is required` })
      .max(SESSION_TITLE_MAX_LENGTH),
    startAt: z.union([
      z.string().transform((str) => {
        const date = new Date(str);
        if (isNaN(date.getTime())) {
          throw new Error(t`Invalid date format: ${str}`);
        }
        return date;
      }),
      z.date(),
    ]),
    duration: z.number({ required_error: t`Duration is required` }),
    coachId: getCoachSchema().shape.id,
  });

export const getSessionSchema = () =>
  getCreateSessionSchema().extend({
    id: z.number(),
    status: z.nativeEnum(ADMIN_SESSION_STATUS),
    joinUrl: z.string().url().nullable(),
    recordingUrl: z.string().url().nullable(),
    isSubscribable: z.boolean(),
    reasonToBlockSubscription: z
      .nativeEnum(BLOCK_SUBSCRIPTION_REASON)
      .nullable(),
    coach: getCoachSchema().catch({ name: "-", id: -1, email: "-" }),
  });

export const getAdminSessionSchema = () =>
  getSessionSchema()
    .omit({ isSubscribable: true, reasonToBlockSubscription: true })
    .extend({
      enrolledUsers: z.number().catch(0),
    });
