import type { ComponentProps } from "react";
import { useState } from "react";
import * as Popover from "@radix-ui/react-popover";
import { format } from "date-fns";
import type { DayPicker } from "react-day-picker";
import type { VariantProps } from "tailwind-variants";
import { tv } from "tailwind-variants";

import { Calendar } from "../common";
import { selectVariants } from "./Select";

const datePickerVariants = tv({
  slots: {
    trigger: selectVariants().trigger(),
    content: selectVariants().content({
      className: "max-h-138 max-w-138 rounded-2xl border-none bg-transparent",
    }),
  },
  variants: {
    readOnly: {
      true: {
        trigger:
          "text-neutrals-dark-500 pointer-events-none border-transparent px-0 font-medium shadow-none focus:border-transparent focus:ring-0",
      },
    },
  },
});

type DatePickerProps = VariantProps<typeof datePickerVariants> &
  Pick<
    ComponentProps<typeof DayPicker>,
    "disabled" | "startMonth" | "endMonth"
  > & {
    value?: Date;
    onChange: (date?: Date) => void;
    className?: string;
  };

export const DatePicker = ({
  value,
  onChange,
  readOnly,
  className,
  ...rest
}: DatePickerProps) => {
  const { trigger, content } = datePickerVariants({ readOnly, className });

  const [month, setMonth] = useState(value);

  const handleDateChange = (newDate: Date | undefined) => {
    if (!newDate) {
      return;
    }

    if (value) {
      // Combine the new date with the time from the current selected date
      const updatedDate = new Date(
        newDate.getFullYear(),
        newDate.getMonth(),
        newDate.getDate(),
        value.getHours(),
        value.getMinutes(),
        value.getSeconds(),
      );
      onChange(updatedDate);
      setMonth(updatedDate);
    } else {
      onChange(newDate);
    }
  };

  return (
    <Popover.Root>
      <Popover.Trigger
        onClick={() => setMonth(value)}
        className={trigger({ className })}
      >
        {value ? (
          format(value, "dd/MM/yyyy")
        ) : (
          <span className="text-brown-06">--/--/----</span>
        )}
      </Popover.Trigger>

      <Popover.Content sideOffset={8} className={content()} align="center">
        <Calendar
          {...rest}
          month={month}
          onMonthChange={setMonth}
          captionLayout="dropdown"
          mode="single"
          selected={value}
          onSelect={handleDateChange}
          required
          className="size-full"
        />
      </Popover.Content>
    </Popover.Root>
  );
};
