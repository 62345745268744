import type { ComponentProps } from "react";
import { t } from "ttag";

import { EmptyState, HeartsCircleFixedIconMono } from "@/ui";

export const ErrorState = (props: ComponentProps<typeof EmptyState.Root>) => {
  return (
    <EmptyState.Root {...props}>
      <HeartsCircleFixedIconMono className="size-14" />

      <EmptyState.Title>{t`Oops seems like something went wrong`}</EmptyState.Title>

      <EmptyState.Description className="text-base">
        {t`Please try again or reach out to support if this issue continue.`}
        <br />
        {t`Thank you for your patience!`}
      </EmptyState.Description>
    </EmptyState.Root>
  );
};
