import type { ComponentPropsWithoutRef } from "react";
import type { Control } from "react-hook-form";
import { t } from "ttag";

import { generateDurationIntervalOptions } from "@/shared/utils";
import { Form } from "@/ui";
import { Select } from "@/ui/form/Select";
import type { AdminSession } from "../models";

const options = generateDurationIntervalOptions();

type SessionDurationControl<T> =
  T extends Pick<AdminSession, "duration"> ? Control<T, "duration"> : never;

type SessionDurationFieldProps<T extends Pick<AdminSession, "duration">> =
  ComponentPropsWithoutRef<typeof Form.Item> & {
    control: SessionDurationControl<T>;
  };

export const SessionDurationField = <T extends Pick<AdminSession, "duration">>({
  control,
  ...props
}: SessionDurationFieldProps<T>) => {
  return (
    <Form.Field
      control={control}
      name="duration"
      render={({ field }) => (
        <Form.Item {...props}>
          <Form.Label aria-required>{t`Duration`}</Form.Label>
          <Form.Control>
            <Select options={options} {...field} />
          </Form.Control>
          <Form.Message compact={false} />
        </Form.Item>
      )}
    />
  );
};
