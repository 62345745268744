import { tw } from "@/utils";
import { AltArrowLeftIcon } from "../icons";
import { icons } from "./Icons";

interface PaginationProps {
  totalPages: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}

export const Pagination = ({
  totalPages,
  currentPage,
  onPageChange,
}: PaginationProps) => {
  if (totalPages < 2) {
    return null;
  }

  const handlePageChange = (page: number) => {
    if (page !== currentPage) {
      onPageChange(page);
    }
  };

  return (
    <nav
      role="navigation"
      aria-label="pagination"
      className="flex items-center"
    >
      <button
        type="button"
        aria-label="Go to previous page"
        disabled={currentPage === 1}
        onClick={() => handlePageChange(currentPage - 1)}
        className="p-3 leading-tight hover:opacity-80 disabled:opacity-30"
      >
        <AltArrowLeftIcon className="size-4 text-brown-08" />
      </button>

      <ul className="flex items-center gap-2">
        {Array.from(Array(totalPages).keys()).map((idx) => {
          const pageNumber = idx + 1;
          const isLimitPage = pageNumber === 1 || pageNumber === totalPages;
          const isSurroundingCurrentPage =
            pageNumber > currentPage - 2 && pageNumber < currentPage + 2;

          if (
            (pageNumber === currentPage - 2 ||
              pageNumber === currentPage + 2) &&
            !isLimitPage
          ) {
            return (
              <li
                aria-hidden
                key={idx}
                className="w-8 select-none border-2 border-transparent py-2 text-center font-medium"
              >
                ...
              </li>
            );
          }

          if (!isSurroundingCurrentPage && !isLimitPage) {
            return null;
          }

          return (
            <li key={idx}>
              <button
                aria-current="page"
                onClick={() => handlePageChange(pageNumber)}
                className={tw(
                  "size-10 rounded-md border border-transparent font-medium text-brown-09",
                  pageNumber === currentPage && "border-brown-06 bg-brown-03",
                )}
              >
                {pageNumber}
              </button>
            </li>
          );
        })}
      </ul>

      <button
        type="button"
        aria-label="Go to next page"
        disabled={currentPage === totalPages}
        onClick={() => handlePageChange(currentPage + 1)}
        className="p-3 leading-tight hover:opacity-80 disabled:opacity-30"
      >
        <icons.ChevronRight className="h-4 w-4 text-brown-08" />
      </button>
    </nav>
  );
};
