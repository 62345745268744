import type { ComponentPropsWithoutRef, ForwardedRef } from "react";
import { Slot } from "@radix-ui/react-slot";
import type { VariantProps } from "tailwind-variants";
import { tv } from "tailwind-variants";

import { forwardRef } from "@/utils";

const buttonVariance = tv({
  base: "flex h-fit shrink-0 items-center justify-center gap-2 truncate rounded-lg border border-transparent py-2.5 font-medium focus:outline-none focus:ring-1 focus:ring-nature-03 disabled:cursor-not-allowed",
  variants: {
    variant: {
      primary:
        "bg-nature-08 text-salmon-01 hover:bg-nature-07 disabled:bg-brown-04",
      secondary:
        "border-nature-08 text-nature-08 hover:border-nature-07 hover:text-nature-07 disabled:border-brown-04 disabled:text-brown-04",
      tertiary:
        "bg-transparent text-nature-08 outline-offset-2 hover:text-nature-07 hover:underline focus:underline focus:outline-none focus:ring-0 disabled:text-brown-04",
      "cover-primary":
        "w-full bg-salmon-01 text-salmon-10 hover:bg-salmon-02 disabled:bg-salmon-01/70 sm:w-fit",
      "cover-secondary":
        "w-full border-salmon-01 text-salmon-01 hover:border-salmon-03 hover:text-salmon-03 sm:w-fit",
    },
    size: {
      xs: "rounded px-4 py-1 text-xs",
      sm: "px-4 py-2 text-xs",
      lg: "px-6 text-base",
    },
    color: {
      red: "",
      brown: "",
    },
  },

  compoundVariants: [
    {
      variant: "primary",
      color: "brown",
      className: "bg-brown-02 text-nature-10 hover:bg-brown-03",
    },
    {
      variant: "secondary",
      color: "red",
      className:
        "border-red-08 text-red-08 hover:border-red-07 hover:text-red-07",
    },
    {
      variant: "tertiary",
      color: "red",
      className: "text-red-08 hover:text-red-07",
    },
  ],

  defaultVariants: {
    size: "sm",
    variant: "primary",
  },
});
type ButtonVariance = VariantProps<typeof buttonVariance>;

interface ButtonProps
  extends Omit<ComponentPropsWithoutRef<"button">, "color">,
    ButtonVariance {
  asChild?: boolean;
}

export const Button = forwardRef(
  (
    {
      type = "button",
      className,
      asChild,
      variant,
      size,
      color,
      ...props
    }: ButtonProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const Component = asChild ? Slot : "button";

    return (
      <Component
        ref={ref}
        type={type}
        className={buttonVariance({ variant, size, color, className })}
        {...props}
      />
    );
  },
);
