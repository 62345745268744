import { Link } from "react-router-dom";
import type { VariantProps } from "tailwind-variants";
import { tv } from "tailwind-variants";
import { t } from "ttag";

import { ROUTES } from "@/router";
import { AddCircleIcon, Button } from "@/ui";

const buttonVariants = tv({
  slots: {
    container: "",
    button: "",
  },
  variants: {
    position: {
      header: {
        container: "hidden sm:flex",
      },
      body: {
        container:
          "sticky bottom-0 -m-6 mt-0 bg-salmon-01 p-6 py-5 shadow-modal-footer sm:hidden",
        button: "w-full",
      },
    },
  },
  defaultVariants: {
    position: "body",
  },
});
type ButtonVariants = VariantProps<typeof buttonVariants>;

export const CreateSessionButton = ({ position }: ButtonVariants) => {
  const { container, button } = buttonVariants({ position });

  return (
    <div className={container()}>
      <Button
        size="lg"
        variant={position === "header" ? "cover-primary" : "primary"}
        className={button()}
        asChild
      >
        <Link to={ROUTES.COACHING.CREATE_SESSION}>
          <AddCircleIcon className="size-4" />
          {t`Create session`}
        </Link>
      </Button>
    </div>
  );
};
