import type { Option } from "@/ui";

const DURATION_INTERVAL = 15;

const pad = (n: number): string => String(n).padStart(2, "0");

export const generateTimeIntervalOptions = () => {
  const intervals: Option<string>[] = [];

  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += DURATION_INTERVAL) {
      const label = `${pad(hour)}:${pad(minute)}`;
      intervals.push({ value: `${label}:00`, label });
    }
  }

  return intervals;
};

export const getClosestTimeOption = (
  intervals: ReturnType<typeof generateTimeIntervalOptions>,
  date: Date,
) => {
  const currentSeconds =
    date.getHours() * 3600 + date.getMinutes() * 60 + date.getSeconds();

  return intervals.reduce((closest, interval) => {
    const [hours, minutes, seconds] = interval.value.split(":").map(Number);
    const intervalSeconds =
      (hours ?? 0) * 3600 + (minutes ?? 0) * 60 + (seconds ?? 0);

    return Math.abs(intervalSeconds - currentSeconds) <
      Math.abs(
        closest.value
          .split(":")
          .map(Number)
          .reduce((a, b) => a * 60 + b) - currentSeconds,
      )
      ? interval
      : closest;
  });
};

export const getClosestDate = (
  intervals: ReturnType<typeof generateTimeIntervalOptions>,
  date: Date,
) => {
  const [closestHours, closestMinutes, closestSeconds] = getClosestTimeOption(
    intervals,
    date,
  )
    .value.split(":")
    .map(Number);

  const updatedDate = new Date(date);
  updatedDate.setHours(closestHours ?? 0);
  updatedDate.setMinutes(closestMinutes ?? 0);
  updatedDate.setSeconds(closestSeconds ?? 0);

  return updatedDate;
};

const MIN_DURATION = 15;
const MAX_DURATION = 120;

export const generateDurationIntervalOptions = () => {
  const intervals = [];

  for (
    let minutes = MIN_DURATION;
    minutes <= MAX_DURATION;
    minutes += DURATION_INTERVAL
  ) {
    intervals.push({
      value: minutes,
      label: `${minutes} minutes`,
    });
  }

  return intervals;
};
