import { useQuery } from "@tanstack/react-query";

import type { getSessions } from "../api";
import { coachingQueriesStore } from "../api";

export const useSessions = (params: Parameters<typeof getSessions>[0]) => {
  return useQuery(
    coachingQueriesStore.coaching.patient._ctx.session._ctx.list(params),
  );
};
