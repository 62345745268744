import { useMutation, useQueryClient } from "@tanstack/react-query";
import { t } from "ttag";

import { updatePatientLanguagePreference } from "@/api";
import { LanguageSwitch } from "@/components";
import { ROUTES } from "@/router";
import { AVAILABLE_LANGUAGE } from "@/shared";
import { errorToast, Header, UserIcon } from "@/ui";
import { EditProfileButton } from "../components";

export const ProfileOverviewHeader = () => {
  const queryClient = useQueryClient();
  const { mutate: updatePatientLanguagePreferenceMutation, isPending } =
    useMutation({
      mutationFn: updatePatientLanguagePreference.mutation,
      onSuccess: () => {
        updatePatientLanguagePreference.invalidates(queryClient);
      },
      onError: errorToast,
    });

  return (
    <Header.Root>
      <Header.Background />

      <Header.Content>
        <Header.Breadcrumb
          links={[
            {
              href: ROUTES.DOCUMENT_CENTER.OVERVIEW,
              icon: UserIcon,
              label: t`My profile`,
            },
          ]}
        />

        <div className="flex justify-between">
          <Header.Title>{t`My profile`}</Header.Title>

          <Header.Actions className="gap-2">
            <LanguageSwitch
              disabled={isPending}
              onCheckedChange={(checked) =>
                updatePatientLanguagePreferenceMutation(
                  checked ? AVAILABLE_LANGUAGE.ES : AVAILABLE_LANGUAGE.EN,
                )
              }
            />
            <EditProfileButton position="header" />
          </Header.Actions>
        </div>
      </Header.Content>
    </Header.Root>
  );
};
