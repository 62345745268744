import type { HTMLAttributes } from "react";
import { cva } from "class-variance-authority";
import type { VariantProps } from "class-variance-authority";
import { t } from "ttag";

import { MEDICATION_REQUEST_STATUS } from "@/shared.constants";
import type { MedicationRequestStatus } from "@/shared.types";
import {
  CheckCircleIcon,
  ChecklistIcon,
  CloseCircleIcon,
  DollarMinimalisticIcon,
  IconWrapper,
  MapArrowRightIcon,
  PlaneIcon,
  ReloadCircleIcon,
  SadCircleIcon,
  TagIcon,
  WarningCircleIcon,
} from "@/ui";
import { tw } from "@/utils";

const chipVariants = cva(
  "inline-flex items-center justify-center rounded-3xl px-2.5 py-0.5 gap-1 text-sm w-fit text-brown-01",
  {
    variants: {
      status: {
        [MEDICATION_REQUEST_STATUS.PENDING]: "bg-yellow-06",
        [MEDICATION_REQUEST_STATUS.PAID]: "bg-nature-08",
        [MEDICATION_REQUEST_STATUS.APPROVED]: "bg-nature-09",
        [MEDICATION_REQUEST_STATUS.ON_ITS_WAY]: "bg-nature-10",
        [MEDICATION_REQUEST_STATUS.CANCELLED]: "bg-brown-07",
        [MEDICATION_REQUEST_STATUS.PROCESS_COMPLETE]: "bg-green-07",
        [MEDICATION_REQUEST_STATUS.DENIED]: "bg-red-07",
        [MEDICATION_REQUEST_STATUS.DELIVERY_UPDATE_REQUESTED]: "bg-yellow-07",
        [MEDICATION_REQUEST_STATUS.SHIPPED]: "bg-nature-07",
        [MEDICATION_REQUEST_STATUS.REFUND_REQUESTED]: "bg-yellow-07",
        [MEDICATION_REQUEST_STATUS.BRANDED_MEDICATION]:
          "bg-salmon-04 text-brown-10",
        [MEDICATION_REQUEST_STATUS.ARCHIVED]: "bg-yellow-07",
      },
    },
    defaultVariants: {
      status: MEDICATION_REQUEST_STATUS.PENDING,
    },
  },
);

const statusIcon = {
  [MEDICATION_REQUEST_STATUS.PENDING]: <ReloadCircleIcon />,
  [MEDICATION_REQUEST_STATUS.PAID]: <DollarMinimalisticIcon />,
  [MEDICATION_REQUEST_STATUS.APPROVED]: <ChecklistIcon />,
  [MEDICATION_REQUEST_STATUS.ON_ITS_WAY]: <PlaneIcon />,
  [MEDICATION_REQUEST_STATUS.CANCELLED]: <CloseCircleIcon />,
  [MEDICATION_REQUEST_STATUS.PROCESS_COMPLETE]: <CheckCircleIcon />,
  [MEDICATION_REQUEST_STATUS.DENIED]: <SadCircleIcon />,
  [MEDICATION_REQUEST_STATUS.DELIVERY_UPDATE_REQUESTED]: <WarningCircleIcon />,
  [MEDICATION_REQUEST_STATUS.SHIPPED]: <MapArrowRightIcon />,
  [MEDICATION_REQUEST_STATUS.REFUND_REQUESTED]: <DollarMinimalisticIcon />,
  [MEDICATION_REQUEST_STATUS.BRANDED_MEDICATION]: <TagIcon />,
  [MEDICATION_REQUEST_STATUS.ARCHIVED]: <WarningCircleIcon />,
};

const getStatusLabel = (status: MedicationRequestStatus) => {
  const statusLabel = {
    [MEDICATION_REQUEST_STATUS.PENDING]: t`Pending`,
    [MEDICATION_REQUEST_STATUS.PAID]: t`Paid`,
    [MEDICATION_REQUEST_STATUS.APPROVED]: t`Approved`,
    [MEDICATION_REQUEST_STATUS.ON_ITS_WAY]: t`On its way`,
    [MEDICATION_REQUEST_STATUS.CANCELLED]: t`Canceled`,
    [MEDICATION_REQUEST_STATUS.PROCESS_COMPLETE]: t`Process complete`,
    [MEDICATION_REQUEST_STATUS.DENIED]: t`Denied`,
    [MEDICATION_REQUEST_STATUS.DELIVERY_UPDATE_REQUESTED]: t`Checking status`,
    [MEDICATION_REQUEST_STATUS.SHIPPED]: t`Shipped`,
    [MEDICATION_REQUEST_STATUS.REFUND_REQUESTED]: t`Refund request`,
    [MEDICATION_REQUEST_STATUS.BRANDED_MEDICATION]: t`Branded`,
    [MEDICATION_REQUEST_STATUS.ARCHIVED]: t`Archived`,
  };

  return statusLabel[status];
};

interface MedicationStatusChipProps
  extends HTMLAttributes<HTMLDivElement>,
    Omit<VariantProps<typeof chipVariants>, "status"> {
  status: MedicationRequestStatus;
}

export const MedicationStatusChip = ({
  className,
  status,
  ...props
}: MedicationStatusChipProps) => {
  return (
    <div className={tw(chipVariants({ status }), className)} {...props}>
      {getStatusLabel(status)}
      <IconWrapper size="sm">{statusIcon[status]}</IconWrapper>
    </div>
  );
};
