import { format } from "date-fns";
import { t } from "ttag";

import { FullScreenLoader } from "@/components";
import {
  useCanJoinSession,
  useUnsubscribeToSession,
  useUpcomingSessions,
} from "@/domains/coaching/hooks";
import { SESSION_COLOR_MAP, SESSION_ICON_MAP } from "@/domains/coaching/models";
import { extractTimeFromDate } from "@/shared/utils";
import {
  Button,
  CardPrimitive,
  ClockIcon,
  DetailCard,
  IconWrapper,
  UserCircleIcon,
  UserCrossIcon,
  UserHeartRoundedIcon,
  VideoCameraIcon,
} from "@/ui";

export const UpcomingSession = () => {
  const { data } = useUpcomingSessions();
  const session = data?.[0];

  const { canJoin } = useCanJoinSession(session?.startAt, session?.duration);

  const unregisterMutation = useUnsubscribeToSession();

  const handleUnregister = () => {
    if (session?.id) {
      unregisterMutation.mutate(session.id);
    }
  };

  if (!session) {
    return null;
  }

  return (
    <CardPrimitive.Root className="bg-transparent p-0">
      <CardPrimitive.Title>
        <UserHeartRoundedIcon className="size-4" />
        {t`Coming up next`}
      </CardPrimitive.Title>

      <DetailCard.Root
        color={SESSION_COLOR_MAP[session.category]}
        className="grid gap-y-4 py-4 lg:grid-cols-2 lg:py-6"
      >
        <DetailCard.Header>
          <IconWrapper size="sm">
            {SESSION_ICON_MAP[session.category]}
          </IconWrapper>
          <DetailCard.Title className="line-clamp-2">
            {session.title}
          </DetailCard.Title>
        </DetailCard.Header>

        <DetailCard.Content className="gap-4">
          <div className="mt-auto flex flex-wrap gap-2 sm:w-fit sm:flex-nowrap">
            <DetailCard.Badge>
              {session.startAt ? format(session.startAt, "MM/dd/yy") : "-"}
            </DetailCard.Badge>
            <DetailCard.Badge variant="outline">
              {session.startAt
                ? extractTimeFromDate(session.startAt, "HH:mm")
                : "-"}
            </DetailCard.Badge>
          </div>

          <div className="flex flex-row flex-wrap gap-12 sm:w-fit">
            <div className="flex min-w-0 flex-1 items-center gap-1">
              <UserCircleIcon className="size-3.5" />
              <DetailCard.Label>{t`Coach`}</DetailCard.Label>
              <DetailCard.Description className="truncate">
                {session.coach.name}
              </DetailCard.Description>
            </div>

            <div className="flex items-center gap-1">
              <DetailCard.Description>
                {session.duration}
              </DetailCard.Description>
              <DetailCard.Label>{t`min`}</DetailCard.Label>
              <ClockIcon className="size-3.5" />
            </div>
          </div>
        </DetailCard.Content>

        <DetailCard.Footer className="flex-col-reverse gap-x-5 gap-y-1 px-0 sm:flex-row md:w-fit lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:w-full lg:max-w-96 lg:self-center lg:justify-self-end">
          <Button
            onClick={handleUnregister}
            size="lg"
            variant="tertiary"
            className="px-0"
          >
            <UserCrossIcon className="size-4" />
            {t`Unregister`}
          </Button>

          <Button
            disabled={!canJoin}
            size="lg"
            className="grow"
            asChild={canJoin}
          >
            {canJoin ? (
              <a href={session.joinUrl ?? ""} target="_blank" rel="noreferrer">
                <VideoCameraIcon className="size-4" />
                {t`Join now`}
              </a>
            ) : (
              <>
                <VideoCameraIcon className="size-4" />
                {t`Join now`}
              </>
            )}
          </Button>
        </DetailCard.Footer>
      </DetailCard.Root>

      {unregisterMutation.isPending && <FullScreenLoader />}
    </CardPrimitive.Root>
  );
};
