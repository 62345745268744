import { t } from "ttag";

import { useCreateSessionContext } from "@/domains/coaching/context";
import { CardPrimitive } from "@/ui";
import { SessionForm } from "../SessionForm";

export const CreateSessionForm = () => {
  const { sessionForm } = useCreateSessionContext();

  return (
    <CardPrimitive.Root className="bg-brown-02 p-6">
      <CardPrimitive.Title>{t`Main info`} </CardPrimitive.Title>
      <CardPrimitive.Content className="grid gap-x-4 gap-y-0.5 md:grid-cols-2">
        <SessionForm form={sessionForm} />
      </CardPrimitive.Content>
    </CardPrimitive.Root>
  );
};
