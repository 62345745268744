import { t } from "ttag";

import { FullScreenLoader } from "@/components";
import { useBoolean } from "@/hooks";
import { usePortalUserStore } from "@/stores";
import { Button, CalendarFixedIconMono, Dialog } from "@/ui";
import { useUpdateMedicationStatus } from "../hooks";

export const PendingRefillRequestModal = () => {
  const askForMedicationStatus = usePortalUserStore((state) =>
    Boolean(state.portalUser?.askForMedicationStatus),
  );

  const { value: showModal, setFalse: onClose } = useBoolean(
    askForMedicationStatus,
  );

  const updateMedicationStatusMutation = useUpdateMedicationStatus();

  const handleUserResponse = (medicationArrived: boolean) => {
    updateMedicationStatusMutation.mutate(medicationArrived, {
      onSuccess: onClose,
    });
  };

  return (
    <Dialog.Root open={showModal} onOpenChange={onClose}>
      <Dialog.Content size="modal" className="h-fit">
        <div className="flex h-full flex-col gap-6">
          <Dialog.Header>
            <Dialog.Icon>
              <CalendarFixedIconMono color="salmon" className="size-full" />
            </Dialog.Icon>
            <Dialog.Title>{t`Have you received your medication?`}</Dialog.Title>
            <Dialog.Description>{t`We noticed there hasn't been any recent activity on your refill request. Has it been delivered, or would you like us to follow up with our team?`}</Dialog.Description>
          </Dialog.Header>

          <Dialog.Footer variant="sticky">
            <Button
              onClick={() => handleUserResponse(false)}
              size="lg"
              variant="secondary"
            >
              {t`No, please follow up`}
            </Button>

            <Button
              onClick={() => handleUserResponse(true)}
              size="lg"
              variant="primary"
            >
              {t`Yes, I received it`}
            </Button>
          </Dialog.Footer>
        </div>

        {updateMedicationStatusMutation.isPending && <FullScreenLoader />}
      </Dialog.Content>
    </Dialog.Root>
  );
};
