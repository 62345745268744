import { addYears } from "date-fns";
import type { UseFormReturn } from "react-hook-form";
import { t } from "ttag";

import {
  SessionCategoryField,
  SessionCoachField,
  SessionDurationField,
  SessionStartTimeField,
} from "@/domains/coaching/components";
import { NOW } from "@/shared.constants";
import { Form, Input } from "@/ui";
import { DatePicker } from "@/ui/form/DatePicker";
import { SESSION_TITLE_MAX_LENGTH } from "../models";
import type { Session } from "../models";

type FormValues = Pick<
  Session,
  "category" | "coachId" | "duration" | "startAt" | "title"
>;

type FormValuesControl<T> = T extends FormValues ? UseFormReturn<T> : never;

interface SessionFormProps<T extends FormValues> {
  form: FormValuesControl<T>;
}

export const SessionForm = <T extends FormValues>({
  form,
}: SessionFormProps<T>) => {
  return (
    <Form.Provider {...form}>
      <SessionCategoryField control={form.control} />

      <Form.Field
        control={form.control}
        defaultValue=""
        name="title"
        render={({ field }) => (
          <Form.Item>
            <Form.Label aria-required>{t`Title`}</Form.Label>
            <Form.Control>
              <Input
                placeholder={t`E.g. Emotional hunger`}
                maxLength={SESSION_TITLE_MAX_LENGTH}
                {...field}
              />
            </Form.Control>
            <Form.Message compact={false} />
          </Form.Item>
        )}
      />

      <Form.Field
        control={form.control}
        name="startAt"
        render={({ field: { ref: _, ...field } }) => (
          <Form.Item>
            <Form.Label aria-required>{t`Date`}</Form.Label>
            <Form.Control>
              <DatePicker
                {...field}
                startMonth={NOW}
                endMonth={addYears(NOW, 1)}
                disabled={{ before: NOW }}
              />
            </Form.Control>
            <Form.Message compact={false} />
          </Form.Item>
        )}
      />

      <SessionStartTimeField control={form.control} />

      <SessionDurationField control={form.control} />

      <SessionCoachField control={form.control} />
    </Form.Provider>
  );
};
