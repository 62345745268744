import { useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { addMinutes, isAfter, isBefore, subMinutes } from "date-fns";

import { useBoolean } from "@/hooks";
import { coachingQueriesStore } from "../api";
import type { Session } from "../models";

const MINUTES_BEFORE_CALL_STARTS = 10;
const CHECK_INTERVAL_DURATION = 1000 * 60;

export const useCanJoinSession = (
  startAt?: Session["startAt"],
  duration?: Session["duration"],
) => {
  const {
    value: canJoin,
    setFalse: disableJoin,
    setTrue: enableJoin,
  } = useBoolean(false);

  const queryClient = useQueryClient();

  useEffect(() => {
    if (!startAt || !duration) {
      return disableJoin();
    }

    const endAt = addMinutes(startAt, duration);
    const enableTime = subMinutes(startAt, MINUTES_BEFORE_CALL_STARTS);

    const updateCanJoin = () => {
      const now = new Date();
      if (isAfter(now, enableTime) && isBefore(now, endAt)) {
        enableJoin();
      } else {
        disableJoin();
      }

      if (isAfter(now, endAt)) {
        void queryClient.invalidateQueries({
          queryKey:
            coachingQueriesStore.coaching.patient._ctx.session._ctx.upcoming
              .queryKey,
        });
      }
    };

    updateCanJoin();
    const intervalId = setInterval(updateCanJoin, CHECK_INTERVAL_DURATION);

    return () => clearInterval(intervalId);
  }, [startAt, duration, queryClient, enableJoin, disableJoin]);

  return { canJoin };
};
