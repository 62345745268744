import { create } from "zustand";
import { persist } from "zustand/middleware";

import type { AvailableLanguage } from "@/shared";
import { AVAILABLE_LANGUAGE } from "@/shared";

export interface AppSettingsStoreState {
  language: AvailableLanguage;
  setLanguage: (language: AvailableLanguage) => void;
}

export const useAppSettingsStore = create<AppSettingsStoreState>()(
  persist(
    (set) => ({
      language: navigator.language.includes("es")
        ? AVAILABLE_LANGUAGE.ES
        : AVAILABLE_LANGUAGE.EN,
      setLanguage: (language) => set({ language }),
    }),
    {
      name: "portal-settings",
    },
  ),
);
