import { t } from "ttag";

import { AVAILABLE_LANGUAGE } from "./shared";

export const getGenderOptions = () =>
  [
    { value: "male", label: t`Male` },
    { value: "female", label: t`Female` },
  ] as const;

export const LANGUAGE_OPTIONS = [
  { value: AVAILABLE_LANGUAGE.EN, label: "English" },
  { value: AVAILABLE_LANGUAGE.ES, label: "Español" },
] as const;

export const getTimezoneOptions = () =>
  [
    { value: "America/New_York", label: t`Eastern Time` },
    { value: "America/Chicago", label: t`Central Time` },
    { value: "America/Denver", label: t`Mountain Time` },
    { value: "America/Los_Angeles", label: t`Pacific Time` },
    { value: "America/Phoenix", label: t`Arizona Time` },
    { value: "America/Anchorage", label: t`Alaska Time` },
    { value: "Pacific/Honolulu", label: t`Hawaii Time` },
  ] as const;
