import { t } from "ttag";

import { SessionCategories } from "@/domains/coaching/components";
import { useSessionStatus } from "@/domains/coaching/hooks";
import {
  ADMIN_SESSION_STATUS,
  SESSION_STATUS,
} from "@/domains/coaching/models";
import { Tabs } from "@/ui";
import { PastSessions } from "./PastSessions";
import { UpcomingSessions } from "./UpcomingSessions";

export const CoachingHubTabs = () => {
  const {
    status,
    actions: { setPaginatedStatus },
  } = useSessionStatus();

  return (
    <Tabs.Root
      value={status}
      onValueChange={setPaginatedStatus}
      className="flex grow flex-col gap-6 pb-12"
    >
      <Tabs.List>
        <Tabs.Trigger
          key={ADMIN_SESSION_STATUS.UPCOMING}
          value={ADMIN_SESSION_STATUS.UPCOMING}
        >
          {t`Upcoming sessions`}
        </Tabs.Trigger>

        <Tabs.Trigger
          key={ADMIN_SESSION_STATUS.PAST}
          value={ADMIN_SESSION_STATUS.PAST}
        >
          {t`Past sessions`}
        </Tabs.Trigger>
      </Tabs.List>

      {status === SESSION_STATUS.PAST && (
        <p className="text-center font-medium text-nature-08">{t`Missed a session? No worries! You can re-watch all sessions here and stay up to date.`}</p>
      )}

      <SessionCategories />

      <Tabs.Content value={ADMIN_SESSION_STATUS.UPCOMING}>
        <UpcomingSessions />
      </Tabs.Content>

      <Tabs.Content value={ADMIN_SESSION_STATUS.PAST}>
        <PastSessions />
      </Tabs.Content>
    </Tabs.Root>
  );
};
