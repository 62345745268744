import { parseAsInteger, useQueryStates } from "nuqs";

interface PaginationDefaultValues {
  page?: number;
  pageSize?: number;
}

export const usePagination = (defaultValues: PaginationDefaultValues = {}) => {
  const [queryStates, setQueryStates] = useQueryStates(
    {
      page: parseAsInteger.withDefault(defaultValues.page ?? 1),
      pageSize: parseAsInteger.withDefault(defaultValues.pageSize ?? 10),
    },
    {
      urlKeys: {
        pageSize: "page-size",
      },
    },
  );

  const { page, pageSize } = queryStates;

  const changePage = (page: number) => {
    void setQueryStates({
      page,
    });
  };

  const changePageSize = (pageSize: number) => {
    void setQueryStates({
      pageSize,
      page: 1,
    });
  };

  const resetPage = () => changePage(1);

  return {
    page,
    pageSize,
    actions: { changePage, changePageSize, resetPage },
  };
};
