import { t } from "ttag";

import { OnboardingHeader } from "@/components";
import {
  OnboardingActionButtons,
  OnboardingLayout,
} from "@/domains/onboarding/components";
import { getGenderOptions } from "@/shared.options";
import type { BirthData, OnboardingBirthInfoFormValues } from "@/shared.types";
import { Combobox, DateOfBirthInputs, Form, GenderIcon } from "@/ui";
import { calculateAgeByDOB, formatDOB, isValidAge } from "@/utils";
import { VALIDATION_ERROR } from "../mainInfo.constants";
import type { ValidationError } from "../mainInfo.types";
import { useBirthInfoForm } from "./hooks";

interface BirthInfoFormProps {
  savedData?: BirthData | null;
  onValidationError: (validationError: ValidationError) => void;
  onGoBack: () => void;
  onSuccess: (birthInfo: BirthData) => void;
}

export const BirthInfo = ({
  savedData,
  onValidationError,
  onGoBack,
  onSuccess,
}: BirthInfoFormProps) => {
  const form = useBirthInfoForm(savedData);

  const preSubmitValidation = (values: OnboardingBirthInfoFormValues) => {
    if (!isValidAge(calculateAgeByDOB(values.dateOfBirth))) {
      onValidationError(VALIDATION_ERROR.AGE_RANGE);
    } else {
      onSuccess({ ...values, dateOfBirth: formatDOB(values.dateOfBirth) });
    }
  };

  return (
    <OnboardingLayout>
      <div className="flex min-h-modal w-full flex-col gap-6 pb-10 sm:min-h-0 sm:items-center sm:gap-12 sm:pb-20 sm:pt-16">
        <OnboardingHeader
          title={t`Your main info`}
          description={t`(*) Mandatory field`}
        />

        <Form.Provider {...form}>
          <form className="flex w-full max-w-3xl flex-col gap-3.5 sm:gap-6">
            <div className="grid gap-x-5 gap-y-1.5 sm:grid-cols-2">
              <Form.Field
                control={form.control}
                name="dateOfBirth"
                render={({ formState: { errors } }) => (
                  <Form.Item>
                    <Form.Label>{t`Date of birth *`}</Form.Label>
                    <Form.Control>
                      <DateOfBirthInputs register={form.register} />
                    </Form.Control>
                    <Form.Message compact={false}>
                      {errors.dateOfBirth?.month?.message ??
                        errors.dateOfBirth?.day?.message ??
                        errors.dateOfBirth?.year?.message}
                    </Form.Message>
                  </Form.Item>
                )}
              />

              <Form.Field
                control={form.control}
                name="gender"
                render={({ field }) => (
                  <Form.Item>
                    <Form.Label>{t`Sex assigned at birth *`}</Form.Label>
                    <Form.Control>
                      <Combobox
                        {...field}
                        options={getGenderOptions()}
                        placeholder={t`Select a gender`}
                        left={<GenderIcon className="text-salmon-07" />}
                      />
                    </Form.Control>
                    <Form.Message compact={false} />
                  </Form.Item>
                )}
              />
            </div>

            <OnboardingActionButtons
              onCancel={onGoBack}
              continueLabel={t`Next`}
              onContinue={form.handleSubmit(preSubmitValidation)}
            />
          </form>
        </Form.Provider>
      </div>
    </OnboardingLayout>
  );
};
