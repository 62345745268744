import {
  CoachingHubHeader,
  CoachingHubTabs,
  UpcomingSession,
} from "@/domains/coaching/sections";
import { ScreenLayout } from "@/layouts";
import { NutritionCounseling, SeshLinkAlert } from "../components";

export const CoachingHub = () => {
  return (
    <ScreenLayout className="gap-6 pt-0 md:pt-0">
      <SeshLinkAlert />

      <CoachingHubHeader />

      <NutritionCounseling />

      <UpcomingSession />

      <CoachingHubTabs />
    </ScreenLayout>
  );
};
