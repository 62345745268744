import { Link } from "react-router-dom";
import { t } from "ttag";

import { EMBEDDABLES_ONBOARDING_ROUTE } from "@/domains";
import { ROUTES } from "@/router";
import type { PortalUser } from "@/shared";
import { Alert, Button } from "@/ui";

interface IncompleteIntakeFormAlertProps {
  portalUser: PortalUser;
}

export const IncompleteIntakeFormAlert = ({
  portalUser,
}: IncompleteIntakeFormAlertProps) => {
  if (!portalUser.patientId) {
    return null;
  }

  const route = portalUser.isFromEmbeddables
    ? EMBEDDABLES_ONBOARDING_ROUTE.MAIN_INFO
    : ROUTES.INTAKE_FORM;

  return (
    <Alert type="warning" className="border-yellow-05">
      <div className="flex flex-col gap-4 lg:flex-row lg:gap-2">
        <div className="grow">
          <h6 className="font-bold text-yellow-10">
            {t`Please complete the intake form`}
          </h6>
          <p>
            {t`Almost there! To ensure your provider can review your information thoroughly, we kindly ask for a few more details. Without these, we won't be able to provide medication or schedule appointments with you.`}
          </p>
        </div>

        <Button
          asChild
          size="lg"
          className="-ml-6 sm:ml-0 sm:self-start lg:self-center"
        >
          <Link to={route}>{t`Complete intake`}</Link>
        </Button>
      </div>
    </Alert>
  );
};
