import type { ComponentProps } from "react";
import { DayPicker } from "react-day-picker";

import { tw } from "@/utils";
import { MonthSelect, NavButtons, YearSelect } from "./components";

export const Calendar = ({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: ComponentProps<typeof DayPicker>) => {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={tw("size-fit rounded-2xl border bg-white p-5", className)}
      classNames={{
        months: "relative flex flex-row gap-12",

        month: "flex flex-col gap-y-7 mx-auto w-full",

        month_caption:
          "flex items-center justify-center px-9 text-lg font-medium leading-6",

        dropdowns: "dropdown z-20 flex grow gap-2",

        nav: "absolute z-10 top-2.5 flex w-full items-center justify-between gap-x-1",

        button_previous: "disabled:opacity-50 disabled:cursor-not-allowed",

        button_next: "disabled:opacity-50 disabled:cursor-not-allowed",

        month_grid: "text-center",

        week: "[&:not(:last-child)>td]:border-b-2 [&:not(:last-child)>td]:border-white",

        weeks:
          "[&_.range-start.range-end]:!bg-[linear-gradient(90deg,_transparent_50%,_transparent_50%)]",

        weekday: "pb-5 text-sm font-medium text-nature-09",

        day: "size-10 first:rounded-l-full last:rounded-r-full",

        today: "[&>button]:border-salmon-06",

        selected:
          "font-medium text-nature-01 [&>button]:border-secondary [&>button]:bg-nature-07",

        range_middle:
          "bg-secondary-50 [&>button]:border-transparent [&>button]:bg-transparent [&>button]:text-primary-900",

        range_start:
          "range-start bg-[linear-gradient(90deg,_transparent_50%,_#F4F0FF_50%)]",

        range_end:
          "range-end bg-[linear-gradient(90deg,_#F4F0FF_50%,_transparent_50%)]",

        day_button:
          "mx-auto flex size-10 cursor-pointer items-center justify-center rounded-full border border-transparent p-0 hover:border-nature-07",

        outside: "text-nature-04",

        hidden: "invisible",

        disabled:
          "text-nature-03 [&_*]:cursor-not-allowed [&>button]:hover:border-transparent",

        ...classNames,
      }}
      components={{
        MonthsDropdown: MonthSelect,
        YearsDropdown: YearSelect,
        Chevron: NavButtons,
      }}
      {...props}
    />
  );
};
