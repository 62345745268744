import type { VariantProps } from "tailwind-variants";
import { tv } from "tailwind-variants";

export const actionsVariants = tv({
  slots: {
    container: "gap-2",
    tertiaryButton: "",
  },
  variants: {
    position: {
      header: {
        container: "hidden md:flex",
      },
      body: {
        container:
          "sticky bottom-0 z-50 -m-6 mt-0 flex flex-col-reverse bg-salmon-01 p-6 py-5 shadow-modal-footer md:hidden",
      },
    },
    isDraft: {
      true: {
        tertiaryButton: "md:pr-2",
      },
    },
  },
  defaultVariants: {
    position: "body",
  },
});
export type ActionsVariants = VariantProps<typeof actionsVariants>;
