import type { PropsWithChildren } from "react";
import { Navigate, Outlet, Routes } from "react-router-dom";

import { PORTAL_USER_TYPE } from "@/shared";
import { useAuthStore, usePortalUserStore } from "@/stores";
import { ROUTES } from "../constants";

export const RequireLoggedOut = ({ children }: PropsWithChildren) => {
  const isLoggedOut = useAuthStore((state) => !state.token);
  const portalUser = usePortalUserStore((state) => state.portalUser);

  if (!isLoggedOut) {
    switch (portalUser?.type) {
      case PORTAL_USER_TYPE.PATIENT:
        return <Navigate replace to={ROUTES.HOME} />;
      case PORTAL_USER_TYPE.COACHING:
        return <Navigate replace to={ROUTES.COACHING.OVERVIEW} />;
    }
  }

  return <>{children}</>;
};

export const GuestRoutesWrapper = ({ children }: PropsWithChildren) => {
  return (
    <RequireLoggedOut>
      <Routes>{children}</Routes>
      <Outlet />
    </RequireLoggedOut>
  );
};
