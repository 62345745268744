import { t } from "ttag";

import { FullScreenLoader } from "@/components";
import { useDeleteSession } from "@/domains/coaching/hooks";
import type { AdminSession } from "@/domains/coaching/models";
import type { ModalProps } from "@/shared.types";
import { Button, Dialog, ShieldWarningIconMono } from "@/ui";

type DeleteSessionModalProps = ModalProps & {
  session: Pick<AdminSession, "id" | "enrolledUsers">;
};

export const DeleteSessionModal = ({
  show,
  onClose,
  session,
}: DeleteSessionModalProps) => {
  const deleteSessionMutation = useDeleteSession();

  const handleDelete = () => {
    if (session) {
      return deleteSessionMutation.mutate(session.id, { onSuccess: onClose });
    }

    onClose();
  };

  return (
    <Dialog.Root open={show} onOpenChange={onClose}>
      <Dialog.Content size="modal" className="h-fit">
        <Dialog.Header className="pb-8">
          <Dialog.Icon>
            <ShieldWarningIconMono />
          </Dialog.Icon>
          <Dialog.Title>{t`Delete Session`}</Dialog.Title>
          <Dialog.Description>
            {session.enrolledUsers
              ? t`You are about to delete a session that has enrolled users. Once deleted, we'll send them an email letting them know it's been canceled.
Are you sure you want to delete it?`
              : t`You are about to delete a session. Are you sure you want to proceed with the deletion?`}
          </Dialog.Description>
        </Dialog.Header>

        <Dialog.Footer variant="sticky">
          <Button onClick={onClose} variant="secondary" size="lg">
            {t`Go back`}
          </Button>
          <Button size="lg" onClick={handleDelete}>
            {t`Delete session`}
          </Button>
        </Dialog.Footer>

        {deleteSessionMutation.isPending && <FullScreenLoader />}
      </Dialog.Content>
    </Dialog.Root>
  );
};
