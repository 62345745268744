import { parseAsStringEnum, useQueryState } from "nuqs";

import { usePagination } from "@/shared/hooks";
import { SESSION_CATEGORY } from "../models";

export const useSessionCategory = () => {
  const [category, setCategory] = useQueryState(
    "category",
    parseAsStringEnum(Object.values(SESSION_CATEGORY)),
  );

  const {
    actions: { resetPage },
  } = usePagination();

  const setPaginatedCategory = (newCategory: typeof category) => {
    void setCategory(newCategory);
    resetPage();
  };

  return { category, actions: { setPaginatedCategory, setCategory } };
};
