import { CreateSessionActions } from "@/domains/coaching/components";
import { CreateSessionContextProvider } from "@/domains/coaching/context";
import { AdminScreenContentLayout } from "@/domains/coaching/layouts";
import {
  CreateSessionForm,
  CreateSessionHeader,
} from "@/domains/coaching/sections";

export const CreateSession = () => {
  return (
    <AdminScreenContentLayout>
      <CreateSessionContextProvider>
        <CreateSessionHeader />

        <div className="grow">
          <CreateSessionForm />
        </div>

        <CreateSessionActions />
      </CreateSessionContextProvider>
    </AdminScreenContentLayout>
  );
};
