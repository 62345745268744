import type { ComponentPropsWithoutRef, ElementRef } from "react";
import { forwardRef } from "react";
import { Slot } from "@radix-ui/react-slot";
import type { VariantProps } from "class-variance-authority";
import { tv } from "tailwind-variants";

import { SIZE, VARIANT } from "@/shared.constants";
import { tw } from "@/utils";

export const badgeVariants = tv({
  base: "flex h-fit w-fit cursor-default items-center justify-center border",
  variants: {
    rounded: {
      lg: "rounded-lg",
      "3xl": "rounded-3xl",
    },
    size: {
      sm: "px-2.5 py-0.5 text-sm",
      md: "px-2.5 py-0.5 text-sm font-medium",
      lg: "px-3 py-1 text-sm font-medium",
      xl: "px-3 py-2 text-sm font-medium",
    },
    variant: {
      primary: "bg-salmon-10 text-salmon-02",
      secondary: "bg-brown-02 text-brown-09",
      outline: "border-salmon-07 bg-transparent text-salmon-09",
    },
    clickable: { true: "cursor-pointer" },
    selected: { true: "" },
  },

  compoundVariants: [
    {
      variant: "secondary",
      clickable: true,
      className: "hover:bg-salmon-07 hover:text-salmon-02",
    },
    {
      variant: "secondary",
      selected: true,
      className:
        "bg-salmon-10 text-salmon-02 hover:bg-salmon-10 hover:text-salmon-02",
    },
  ],

  defaultVariants: {
    rounded: "lg",
    variant: "primary",
    size: "sm",
  },
});

type BadgeProps = ComponentPropsWithoutRef<"div"> &
  VariantProps<typeof badgeVariants> & {
    label?: string;
    asChild?: boolean;
  };

export const Badge = forwardRef<ElementRef<"div">, BadgeProps>(
  (
    {
      className,
      asChild,
      variant = VARIANT.PRIMARY,
      size = SIZE.SMALL,
      rounded,
      clickable,
      selected,
      ...props
    },
    ref,
  ) => {
    const Component = asChild ? Slot : "div";

    return (
      <Component
        ref={ref}
        className={tw(
          badgeVariants({
            variant,
            size,
            className,
            rounded,
            clickable,
            selected,
          }),
        )}
        {...props}
      />
    );
  },
);
Badge.displayName = "Badge";
