import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

import { useEmbeddablesForms } from "@/domains/embeddables-onboarding/context";
import type { MainInfo } from "@/domains/embeddables-onboarding/models";
import { getMainInfoSchema } from "@/domains/embeddables-onboarding/models";
import { useAppSettingsStore } from "@/stores";
import { parseDOB } from "@/utils";

export const useMainInfoForm = () => {
  const { patient, mainInfoFormValues } = useEmbeddablesForms();
  const appLanguage = useAppSettingsStore((state) => state.language);

  return useForm<MainInfo>({
    resolver: zodResolver(getMainInfoSchema()),
    defaultValues: mainInfoFormValues ?? {
      dateOfBirth: patient.dateOfBirth && parseDOB(patient.dateOfBirth),
      location: { ...patient.location, country: "US" },
      preferredLanguage: appLanguage,
    },
  });
};
