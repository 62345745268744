import { t } from "ttag";

import { Badge, Button } from "@/ui";
import { useSessionCategory, useSessionStatus } from "../hooks";
import {
  getSessionCategoryLabel,
  SESSION_CATEGORY,
  SESSION_STATUS,
} from "../models";

export const SessionCategories = () => {
  const { status } = useSessionStatus();
  const {
    category,
    actions: { setPaginatedCategory },
  } = useSessionCategory();

  const isPastSession = status === SESSION_STATUS.PAST;

  return (
    <div className="flex flex-wrap items-center justify-center gap-2.5">
      <Badge
        asChild
        rounded="3xl"
        size="xl"
        variant="secondary"
        clickable
        selected={!category}
      >
        <Button
          onClick={() => setPaginatedCategory(null)}
        >{t`All sessions`}</Button>
      </Badge>

      {Object.values(SESSION_CATEGORY).map((sessionCategory) => {
        if (sessionCategory === SESSION_CATEGORY.GROUP && isPastSession) {
          return null;
        }

        return (
          <Badge
            key={sessionCategory}
            asChild
            rounded="3xl"
            size="xl"
            variant="secondary"
            clickable
            selected={category === sessionCategory}
          >
            <Button onClick={() => setPaginatedCategory(sessionCategory)}>
              {getSessionCategoryLabel(sessionCategory)}
            </Button>
          </Badge>
        );
      })}
    </div>
  );
};
