import type { ServiceResponse } from "@/api";
import { privateAPI } from "@/api";

export const updateMedicationRequestStatus = async (
  medicationArrived: boolean,
) => {
  const { data } = await privateAPI.put<ServiceResponse<null>>(
    `/medication-status`,
    { medicationArrived },
  );

  return data.data;
};
