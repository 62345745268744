import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import type { CheckedState } from "@radix-ui/react-checkbox";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { jt, t } from "ttag";

import { getStatesQuery } from "@/api";
import { FullScreenLoader, OnboardingHeader } from "@/components";
import { createOnboardingUser } from "@/domains/onboarding/api";
import {
  OnboardingActionButtons,
  OnboardingLayout,
} from "@/domains/onboarding/components";
import { EXTERNAL_LINK, ROUTES } from "@/router";
import { LANGUAGE_OPTIONS } from "@/shared.options";
import { getOnboardingPatientSchema } from "@/shared.schemas";
import type { OnboardingPatientFormValues } from "@/shared.types";
import { useAppSettingsStore, useOnboardingStore } from "@/stores";
import {
  Checkbox,
  Combobox,
  Form,
  Home2Icon,
  Input,
  Label,
  LetterIcon,
  Loader,
  TranslationIcon,
  UserIcon,
} from "@/ui";
import { handleAxiosFieldErrors } from "@/utils";
import { VALIDATION_ERROR } from "./mainInfo.constants";
import type { ValidationError } from "./mainInfo.types";

interface MainInfoFormProps {
  onValidation: (validationError: ValidationError) => void;
  onGoBack: () => void;
}

export const MainInfoForm = ({ onValidation, onGoBack }: MainInfoFormProps) => {
  const [hasConsent, setHasConsent] = useState<CheckedState>(false);
  const setUser = useOnboardingStore((state) => state.setUser);
  const savedUser = useOnboardingStore((state) => state.user);
  const language = useAppSettingsStore((state) => state.language);

  const { data: stateOptions, isLoading: isLoadingStates } =
    useQuery(getStatesQuery());

  const form = useForm<OnboardingPatientFormValues>({
    resolver: zodResolver(getOnboardingPatientSchema()),
    defaultValues: { ...savedUser, language, hasMedicaidCoverage: false },
  });

  const { control, setError, handleSubmit } = form;

  const navigate = useNavigate();

  const { mutate: createUserMutation, isPending } = useMutation({
    mutationFn: createOnboardingUser.mutation,
    onSuccess: (data) => {
      setUser(data);
      navigate(ROUTES.ONBOARDING.JOURNEY_BEGIN);
    },
    onError: (error) => {
      handleAxiosFieldErrors(error, setError);
    },
  });

  const preSubmitValidation = (values: OnboardingPatientFormValues) => {
    if (!savedUser) {
      return;
    }

    const { hasMedicaidCoverage } = values;

    if (hasMedicaidCoverage) {
      onValidation(VALIDATION_ERROR.HAS_MEDICAID);
    } else {
      createUserMutation({ values, id: savedUser.id });
    }
  };

  const benefits = (
    <strong key="updates-offers-bold" className="text-brown-10">
      {t`exclusive updates, special offers`}
    </strong>
  );
  const healthTips = (
    <strong
      key="health-tips-bold"
      className="text-brown-10"
    >{t`health tips`}</strong>
  );
  const privacyLink = (
    <Link
      key={EXTERNAL_LINK.PRIVACY_AND_POLICY}
      to={EXTERNAL_LINK.PRIVACY_AND_POLICY}
      target="_blank"
      className="font-bold text-brown-10 underline"
    >{t`Privacy Policy`}</Link>
  );
  const termsAndConditionsLink = (
    <Link
      key={EXTERNAL_LINK.TERMS_AND_CONDITIONS}
      to={EXTERNAL_LINK.TERMS_AND_CONDITIONS}
      target="_blank"
      className="font-bold text-brown-10 underline"
    >{t`Terms of service`}</Link>
  );

  return (
    <OnboardingLayout className="bg-brown-02">
      {isPending && <FullScreenLoader />}
      <div className="flex w-full max-w-4xl grow flex-col gap-6 pb-10 sm:items-center sm:gap-12 sm:pb-20">
        <OnboardingHeader
          title={t`Tell us about yourself`}
          description={t`(*) Mandatory field`}
        />

        <Form.Provider {...form}>
          <form className="flex w-full flex-col gap-3.5 sm:gap-6">
            <div className="grid grow gap-x-5 gap-y-1.5 md:grid-cols-2">
              <Form.Field
                control={control}
                name="firstName"
                render={({ field }) => (
                  <Form.Item>
                    <Form.Label>{t`First name *`}</Form.Label>
                    <Form.Control>
                      <Input
                        autoComplete="given-name"
                        placeholder={t`E.g. John`}
                        left={<UserIcon />}
                        {...field}
                      />
                    </Form.Control>
                    <Form.Message compact={false} />
                  </Form.Item>
                )}
              />

              <Form.Field
                control={control}
                name="lastName"
                render={({ field }) => (
                  <Form.Item>
                    <Form.Label>{t`Last name *`}</Form.Label>
                    <Form.Control>
                      <Input
                        autoComplete="family-name"
                        placeholder={t`E.g. Doe`}
                        left={<UserIcon />}
                        {...field}
                      />
                    </Form.Control>
                    <Form.Message compact={false} />
                  </Form.Item>
                )}
              />

              <Form.Field
                control={control}
                name="email"
                render={({ field }) => (
                  <Form.Item>
                    <Form.Label>{t`Email *`}</Form.Label>
                    <Form.Control>
                      <Input
                        type="email"
                        autoComplete="email"
                        placeholder={t`E.g. email@example.com`}
                        left={<LetterIcon />}
                        {...field}
                      />
                    </Form.Control>
                    <Form.Message compact={false} />
                  </Form.Item>
                )}
              />

              <Form.Field
                control={control}
                name="emailConfirmation"
                render={({ field }) => (
                  <Form.Item>
                    <Form.Label>{t`Confirm email *`}</Form.Label>
                    <Form.Control>
                      <Input
                        placeholder={t`E.g. email@example.com`}
                        left={<LetterIcon />}
                        {...field}
                      />
                    </Form.Control>
                    <Form.Message compact={false} />
                  </Form.Item>
                )}
              />

              <Form.Field
                control={control}
                name="state"
                render={({ field }) => (
                  <Form.Item>
                    <Form.Label>{t`State *`}</Form.Label>
                    <Form.Control>
                      <Combobox
                        autocomplete
                        placeholder={t`E.g. New York`}
                        left={
                          isLoadingStates ? <Loader size="xs" /> : <Home2Icon />
                        }
                        options={stateOptions ?? []}
                        disabled={isLoadingStates}
                        {...field}
                      />
                    </Form.Control>
                    <Form.Message compact={false} />
                  </Form.Item>
                )}
              />

              <Form.Field
                control={control}
                name="language"
                render={({ field }) => (
                  <Form.Item>
                    <Form.Label>{t`Language of preference *`}</Form.Label>
                    <Form.Control>
                      <Combobox
                        placeholder={t`E.g. English`}
                        left={<TranslationIcon />}
                        options={LANGUAGE_OPTIONS}
                        {...field}
                      />
                    </Form.Control>
                    <Form.Message compact={false} />
                  </Form.Item>
                )}
              />

              <Form.Field
                control={control}
                name="marketingConsent"
                render={({ field: { value, onChange, ...rest } }) => (
                  <Form.Item className="col-span-full md:pt-6">
                    <div className="flex gap-2">
                      <Form.Control>
                        <Checkbox
                          checked={value}
                          onCheckedChange={onChange}
                          containerClassName="grow-0"
                          className="mt-1.5"
                          {...rest}
                        />
                      </Form.Control>
                      <Form.Label className="text-lg font-normal text-brown-08 sm:text-lg">
                        {jt`I would like to receive ${benefits}, and ${healthTips} from Fridays. By checking this box, I consent to receive marketing communications via email and SMS and understand that I can unsubscribe at any time.`}
                      </Form.Label>
                    </div>
                    <Form.Message compact={false} />
                  </Form.Item>
                )}
              />

              <div className="col-span-full flex gap-2 pb-6">
                <Checkbox
                  checked={hasConsent}
                  onCheckedChange={setHasConsent}
                  id="consent-checkbox"
                  containerClassName="grow-0"
                  className="mt-1.5"
                />
                <Label
                  htmlFor="consent-checkbox"
                  className="text-lg font-normal text-brown-08"
                >
                  {jt`I consent to Fridays ${privacyLink} and ${termsAndConditionsLink}`}
                </Label>
              </div>
            </div>

            <OnboardingActionButtons
              onCancel={onGoBack}
              continueLabel={t`Next`}
              onContinue={handleSubmit(preSubmitValidation)}
              continueDisabled={!hasConsent}
            />
          </form>
        </Form.Provider>
      </div>
    </OnboardingLayout>
  );
};
