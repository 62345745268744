import type { ComponentPropsWithoutRef, ElementRef } from "react";
import { forwardRef } from "react";
import * as LabelPrimitive from "@radix-ui/react-label";
import type { VariantProps } from "tailwind-variants";
import { tv } from "tailwind-variants";

import { SIZE } from "@/shared.constants";

const labelVariance = tv({
  base: "text-sm font-medium text-salmon-10 aria-required-[true]:after:content-['_*']",
  variants: {
    size: {
      [SIZE.X_SMALL]: "text-xs",
      [SIZE.SMALL]: "text-sm",
      [SIZE.MEDIUM]: "text-base",
      [SIZE.LARGE]: "text-lg",
      [SIZE.X_LARGE]: "text-xl",
    },
  },
  defaultVariants: {
    size: SIZE.SMALL,
  },
});

export const Label = forwardRef<
  ElementRef<typeof LabelPrimitive.Root>,
  ComponentPropsWithoutRef<typeof LabelPrimitive.Root> &
    VariantProps<typeof labelVariance>
>(({ className, size, ...props }, ref) => (
  <LabelPrimitive.Root
    ref={ref}
    className={labelVariance({ size, className })}
    {...props}
  />
));
Label.displayName = LabelPrimitive.Root.displayName;
