import type { ComponentPropsWithoutRef } from "react";
import type { Control } from "react-hook-form";
import { t } from "ttag";

import { Form } from "@/ui";
import { Select } from "@/ui/form/Select";
import { useCoachOptions } from "../hooks";
import type { AdminSession } from "../models";

type SessionCoachControl<T> =
  T extends Pick<AdminSession, "coachId"> ? Control<T, "coachId"> : never;

type SessionCoachFieldProps<T extends Pick<AdminSession, "coachId">> =
  ComponentPropsWithoutRef<typeof Form.Item> & {
    control: SessionCoachControl<T>;
  };

export const SessionCoachField = <T extends Pick<AdminSession, "coachId">>({
  control,
  ...props
}: SessionCoachFieldProps<T>) => {
  const { data: coachOptions, isLoading } = useCoachOptions();

  return (
    <Form.Field
      control={control}
      name="coachId"
      render={({ field }) => (
        <Form.Item {...props}>
          <Form.Label aria-required>{t`Coach`}</Form.Label>
          <Form.Control>
            <Select options={coachOptions} isLoading={isLoading} {...field} />
          </Form.Control>
          <Form.Message compact={false} />
        </Form.Item>
      )}
    />
  );
};
