import { jt, t } from "ttag";

import { OnboardingHeader } from "@/components";
import { ScheduleContextProvider } from "@/contexts";
import { OnboardingLayout } from "@/domains/onboarding/components";
import { useBoolean } from "@/hooks";
import { AVAILABLE_LANGUAGE } from "@/shared";
import { useOnboardingStore } from "@/stores";
import { Alert, ShieldCheckIcon } from "@/ui";
import {
  AvailableDays,
  AvailableTimes,
  LanguagePreference,
  Submit,
} from "./sections";

interface OnboardingScheduleVisitProps {
  onGoBack: () => void;
}

export const OnboardingScheduleVisit = ({
  onGoBack,
}: OnboardingScheduleVisitProps) => {
  const defaultLanguage = useOnboardingStore(
    (state) => state.user?.language ?? AVAILABLE_LANGUAGE.EN,
  );
  const state = useOnboardingStore((state) => state.user?.state);

  const { value: showLanguageSelector, setTrue: openLanguageSelector } =
    useBoolean(false);

  const selectorOpener = (
    <button
      key="language-selector-opener"
      type="button"
      onClick={openLanguageSelector}
      className="underline"
    >{t`here`}</button>
  );

  return (
    <OnboardingLayout className="bg-brown-02">
      <div className="flex w-full grow flex-col gap-6 pb-12 lg:gap-8">
        <OnboardingHeader
          title={t`Lets meet!`}
          description={t`Choose the perfect time and date`}
        />

        {!showLanguageSelector && (
          <Alert customIcon={<ShieldCheckIcon />}>
            {jt`These are the available consultations based on your language preference. If you'd like, you can change your preferred language for the consultation by clicking ${selectorOpener}.`}
          </Alert>
        )}

        <ScheduleContextProvider
          defaultLanguage={defaultLanguage}
          state={state}
        >
          {showLanguageSelector && <LanguagePreference />}
          <div className="flex grow flex-col gap-8 lg:flex-row">
            <AvailableDays />
            <AvailableTimes />
          </div>

          <Submit onCancel={onGoBack} />
        </ScheduleContextProvider>
      </div>
    </OnboardingLayout>
  );
};
