import type { SVGProps } from "@/shared.types";
import { tw } from "@/utils";

export const UserIcon = ({ className, ...props }: SVGProps) => (
  <svg
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={tw("shrink-0", className)}
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M8.00041 0.833984C6.25151 0.833984 4.83375 2.25175 4.83375 4.00065C4.83375 5.74955 6.25151 7.16732 8.00041 7.16732C9.74932 7.16732 11.1671 5.74955 11.1671 4.00065C11.1671 2.25175 9.74932 0.833984 8.00041 0.833984ZM5.83375 4.00065C5.83375 2.80403 6.8038 1.83398 8.00041 1.83398C9.19703 1.83398 10.1671 2.80403 10.1671 4.00065C10.1671 5.19727 9.19703 6.16732 8.00041 6.16732C6.8038 6.16732 5.83375 5.19727 5.83375 4.00065Z"
    />
    <path
      fillRule="evenodd"
      d="M8.00041 8.16732C6.45805 8.16732 5.03703 8.51791 3.98405 9.11021C2.94674 9.6937 2.16708 10.578 2.16708 11.6673L2.16704 11.7353C2.16628 12.5099 2.16534 13.482 3.01802 14.1763C3.43767 14.5181 4.02473 14.7611 4.81789 14.9216C5.61326 15.0826 6.6499 15.1673 8.00041 15.1673C9.35093 15.1673 10.3876 15.0826 11.1829 14.9216C11.9761 14.7611 12.5632 14.5181 12.9828 14.1763C13.8355 13.482 13.8345 12.5099 13.8338 11.7353L13.8337 11.6673C13.8337 10.578 13.0541 9.6937 12.0168 9.11021C10.9638 8.51791 9.54278 8.16732 8.00041 8.16732ZM3.16708 11.6673C3.16708 11.0998 3.58133 10.4841 4.47431 9.98178C5.35161 9.4883 6.59726 9.16732 8.00041 9.16732C9.40357 9.16732 10.6492 9.4883 11.5265 9.98178C12.4195 10.4841 12.8337 11.0998 12.8337 11.6673C12.8337 12.5392 12.8069 13.03 12.3514 13.4009C12.1043 13.6021 11.6914 13.7984 10.9846 13.9415C10.2799 14.0841 9.31657 14.1673 8.00041 14.1673C6.68426 14.1673 5.72091 14.0841 5.01628 13.9415C4.30943 13.7984 3.89649 13.6021 3.64947 13.4009C3.19396 13.03 3.16708 12.5392 3.16708 11.6673Z"
    />
  </svg>
);

export const UserCircleIcon = ({ className, ...props }: SVGProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={tw("shrink-0", className)}
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M8.25 9C8.25 6.92893 9.92893 5.25 12 5.25C14.0711 5.25 15.75 6.92893 15.75 9C15.75 11.0711 14.0711 12.75 12 12.75C9.92893 12.75 8.25 11.0711 8.25 9ZM12 6.75C10.7574 6.75 9.75 7.75736 9.75 9C9.75 10.2426 10.7574 11.25 12 11.25C13.2426 11.25 14.25 10.2426 14.25 9C14.25 7.75736 13.2426 6.75 12 6.75Z"
    />
    <path
      fillRule="evenodd"
      d="M1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12ZM12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 14.5456 3.77827 16.851 5.4421 18.5235C5.6225 17.5504 5.97694 16.6329 6.68837 15.8951C7.75252 14.7915 9.45416 14.25 12 14.25C14.5457 14.25 16.2474 14.7915 17.3115 15.8951C18.023 16.6329 18.3774 17.5505 18.5578 18.5236C20.2217 16.8511 21.25 14.5456 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75ZM17.1937 19.6554C17.0918 18.4435 16.8286 17.5553 16.2318 16.9363C15.5823 16.2628 14.3789 15.75 12 15.75C9.62099 15.75 8.41761 16.2628 7.76815 16.9363C7.17127 17.5553 6.90811 18.4434 6.80622 19.6553C8.28684 20.6618 10.0747 21.25 12 21.25C13.9252 21.25 15.7131 20.6618 17.1937 19.6554Z"
    />
  </svg>
);

export const UserHeartRoundedIcon = ({ className, ...props }: SVGProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={tw("shrink-0", className)}
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M4.25 6C4.25 3.37665 6.37665 1.25 9 1.25C11.6234 1.25 13.75 3.37665 13.75 6C13.75 8.62335 11.6234 10.75 9 10.75C6.37665 10.75 4.25 8.62335 4.25 6ZM9 2.75C7.20507 2.75 5.75 4.20507 5.75 6C5.75 7.79493 7.20507 9.25 9 9.25C10.7949 9.25 12.25 7.79493 12.25 6C12.25 4.20507 10.7949 2.75 9 2.75Z"
    />
    <path
      fillRule="evenodd"
      d="M3.67815 13.5204C5.07752 12.7208 6.96067 12.25 9 12.25C11.0393 12.25 12.9225 12.7208 14.3219 13.5204C15.7 14.3079 16.75 15.5101 16.75 17C16.75 18.4899 15.7 19.6921 14.3219 20.4796C12.9225 21.2792 11.0393 21.75 9 21.75C6.96067 21.75 5.07752 21.2792 3.67815 20.4796C2.3 19.6921 1.25 18.4899 1.25 17C1.25 15.5101 2.3 14.3079 3.67815 13.5204ZM4.42236 14.8228C3.26701 15.483 2.75 16.2807 2.75 17C2.75 17.7193 3.26701 18.517 4.42236 19.1772C5.55649 19.8253 7.17334 20.25 9 20.25C10.8267 20.25 12.4435 19.8253 13.5776 19.1772C14.733 18.517 15.25 17.7193 15.25 17C15.25 16.2807 14.733 15.483 13.5776 14.8228C12.4435 14.1747 10.8267 13.75 9 13.75C7.17334 13.75 5.55649 14.1747 4.42236 14.8228Z"
    />
    <path
      fillRule="evenodd"
      d="M15.6903 7.44694C16.4109 7.12802 17.2479 7.18847 18 7.67889C18.7521 7.18847 19.5891 7.12802 20.3097 7.44694C21.1648 7.82533 21.75 8.69929 21.75 9.69973C21.75 10.6481 21.3358 11.362 20.8394 11.9031C20.4521 12.3254 19.9729 12.682 19.5945 12.9636C19.5133 13.0241 19.4365 13.0812 19.3665 13.1347L19.3645 13.1362C19.2158 13.2497 19.0235 13.3964 18.8207 13.5111C18.6168 13.6265 18.3344 13.75 18 13.75C17.6656 13.75 17.3832 13.6265 17.1793 13.5111C16.9765 13.3965 16.7842 13.2497 16.6355 13.1362L16.6335 13.1347C16.5635 13.0812 16.4869 13.0242 16.4057 12.9638C16.0273 12.6821 15.5479 12.3254 15.1606 11.9031C14.6642 11.362 14.25 10.6481 14.25 9.69973C14.25 8.69929 14.8352 7.82533 15.6903 7.44694ZM15.75 9.69973C15.75 9.28775 15.9898 8.95469 16.2973 8.81862C16.5635 8.7008 16.9874 8.68874 17.4681 9.17232C17.6089 9.31392 17.8003 9.39354 18 9.39354C18.1997 9.39354 18.3911 9.31392 18.5319 9.17232C19.0126 8.68874 19.4365 8.7008 19.7027 8.81862C20.0102 8.95469 20.25 9.28775 20.25 9.69973C20.25 10.1638 20.0613 10.5324 19.7341 10.8891C19.452 11.1966 19.1157 11.448 18.7438 11.7259C18.6502 11.7959 18.5543 11.8676 18.4565 11.9423C18.2939 12.0663 18.1813 12.1495 18.0821 12.2056C18.0421 12.2283 18.0153 12.2399 18 12.2456C17.9847 12.2399 17.9579 12.2283 17.9179 12.2056C17.8187 12.1495 17.7061 12.0663 17.5435 11.9423C17.4457 11.8676 17.3499 11.796 17.2563 11.726C16.8844 11.448 16.548 11.1966 16.2659 10.8891C15.9387 10.5324 15.75 10.1638 15.75 9.69973Z"
    />
  </svg>
);

export const UserCrossIcon = ({ className, ...props }: SVGProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={tw("shrink-0", className)}
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M12.0001 1.25C9.37678 1.25 7.25013 3.37665 7.25013 6C7.25013 8.62335 9.37678 10.75 12.0001 10.75C14.6235 10.75 16.7501 8.62335 16.7501 6C16.7501 3.37665 14.6235 1.25 12.0001 1.25ZM8.75013 6C8.75013 4.20507 10.2052 2.75 12.0001 2.75C13.7951 2.75 15.2501 4.20507 15.2501 6C15.2501 7.79493 13.7951 9.25 12.0001 9.25C10.2052 9.25 8.75013 7.79493 8.75013 6Z"
    />
    <path d="M17.197 14.1363C16.9041 13.8434 16.4292 13.8434 16.1363 14.1363C15.8434 14.4292 15.8434 14.9041 16.1363 15.197L16.9395 16.0002L16.1366 16.803C15.8437 17.0959 15.8437 17.5708 16.1366 17.8637C16.4295 18.1565 16.9044 18.1565 17.1973 17.8637L18.0001 17.0608L18.803 17.8637C19.0959 18.1565 19.5707 18.1565 19.8636 17.8637C20.1565 17.5708 20.1565 17.0959 19.8636 16.803L19.0608 16.0002L19.864 15.197C20.1569 14.9041 20.1569 14.4292 19.864 14.1363C19.5711 13.8434 19.0962 13.8434 18.8033 14.1363L18.0001 14.9395L17.197 14.1363Z" />
    <path
      fillRule="evenodd"
      d="M12.0001 12.25C12.9686 12.25 13.9021 12.3421 14.7748 12.5129C15.6218 11.7291 16.755 11.25 18.0001 11.25C20.6235 11.25 22.7501 13.3766 22.7501 16C22.7501 17.7601 21.7928 19.2966 20.3705 20.1172C19.9974 20.9266 19.2986 21.5697 18.1197 22.0085C16.81 22.4961 14.8682 22.75 12.0001 22.75C9.97436 22.75 8.41939 22.6229 7.22634 22.3815C6.03661 22.1406 5.15602 21.7761 4.52655 21.2635C3.24752 20.222 3.24894 18.7638 3.25007 17.602L3.25013 17.5C3.25013 15.8661 4.41962 14.5396 5.97558 13.6643C7.55506 12.7759 9.68658 12.25 12.0001 12.25ZM4.75013 17.5C4.75013 16.6487 5.37151 15.7251 6.71098 14.9717C8.02693 14.2315 9.89541 13.75 12.0001 13.75C12.6062 13.75 13.1941 13.79 13.7558 13.865C13.4323 14.5069 13.2501 15.2322 13.2501 16C13.2501 18.3893 15.0142 20.3666 17.311 20.7004C16.2402 21.0366 14.5781 21.25 12.0001 21.25C10.0259 21.25 8.58087 21.1252 7.52392 20.9113C6.46366 20.6967 5.84425 20.4022 5.47372 20.1004C4.79045 19.544 4.75013 18.8078 4.75013 17.5ZM18.0001 12.75C16.2052 12.75 14.7501 14.2051 14.7501 16C14.7501 17.7949 16.2052 19.25 18.0001 19.25C19.7951 19.25 21.2501 17.7949 21.2501 16C21.2501 14.2051 19.7951 12.75 18.0001 12.75Z"
    />
  </svg>
);
