import { t } from "ttag";

import { ROUTES } from "@/router";
import { Header, LightbulbIcon } from "@/ui";

export const CoachingHubHeader = () => {
  return (
    <Header.Root>
      <Header.Background />

      <Header.Content className="grow">
        <Header.Breadcrumb
          links={[
            {
              href: ROUTES.COACHING.OVERVIEW,
              icon: LightbulbIcon,
              label: t`Coaching hub`,
            },
          ]}
        />

        <Header.Title>{t`Coaching hub`}</Header.Title>
      </Header.Content>
    </Header.Root>
  );
};
