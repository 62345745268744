import { Link } from "react-router-dom";
import { t } from "ttag";

import { CreateSessionActions } from "@/domains/coaching/components";
import { ROUTES } from "@/router";
import { AddCircleIcon, Header, LightbulbIcon } from "@/ui";

export const CreateSessionHeader = () => {
  return (
    <Header.Root variant="secondary">
      <Header.Content>
        <Header.Breadcrumb
          links={[
            {
              href: ROUTES.COACHING.OVERVIEW,
              icon: LightbulbIcon,
              label: t`Coaching lab`,
            },
            {
              href: ROUTES.COACHING.CREATE_SESSION,
              icon: AddCircleIcon,
              label: t`Create session`,
            },
          ]}
        />

        <div className="flex items-center justify-between gap-x-8">
          <div className="flex items-center gap-2">
            <Link to={ROUTES.COACHING.OVERVIEW}>
              <Header.GoBackButton className="md:hidden" />
            </Link>

            <Header.Title>{t`Create session`}</Header.Title>
          </div>

          <CreateSessionActions position="header" />
        </div>

        <Header.Description>{t`Required fields *`}</Header.Description>
      </Header.Content>
    </Header.Root>
  );
};
