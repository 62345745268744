import type { PortalUser } from "@/shared";
import { PORTAL_USER_TYPE } from "@/shared";
import {
  getAdminNavigation,
  getCoachingNavigation,
  getPatientNavigation,
} from "../navigation";

export const getNavigation = (userType?: PortalUser["type"] | null) => {
  switch (userType) {
    case PORTAL_USER_TYPE.ADMIN:
      return getAdminNavigation();
    case PORTAL_USER_TYPE.PATIENT:
      return getPatientNavigation();
    case PORTAL_USER_TYPE.COACHING:
      return getCoachingNavigation();
    default:
      return [];
  }
};
