import { zodResolver } from "@hookform/resolvers/zod";
import { addHours } from "date-fns";
import { useForm } from "react-hook-form";

import { NOW } from "@/shared.constants";
import { generateTimeIntervalOptions, getClosestDate } from "@/shared/utils";
import type { CreateSession } from "../models";
import { getCreateSessionSchema } from "../models";

const SESSION_HOURS_OFFSET = 1;

const options = generateTimeIntervalOptions();
const startAt = getClosestDate(options, addHours(NOW, SESSION_HOURS_OFFSET));

export const useCreateSessionForm = () => {
  return useForm<Omit<CreateSession, "published">>({
    resolver: zodResolver(getCreateSessionSchema().omit({ published: true })),
    defaultValues: { startAt },
  });
};
