import type { ComponentPropsWithoutRef, ElementRef } from "react";
import { forwardRef } from "react";
import * as SelectPrimitive from "@radix-ui/react-select";

import { Loader } from "@/ui/common";
import { AltArrowDownIcon, AltArrowUpIcon, CheckIcon } from "@/ui/icons";
import { selectVariants } from "../variants";

const {
  trigger,
  scrollButton,
  content,
  viewport,
  label,
  item,
  itemSelectedIcon,
  separator,
} = selectVariants();

const Root = SelectPrimitive.Root;

const Group = SelectPrimitive.Group;

const Value = SelectPrimitive.Value;

const Trigger = forwardRef<
  ElementRef<typeof SelectPrimitive.Trigger>,
  ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger> & {
    isLoading?: boolean;
  }
>(({ className, children, isLoading, ...props }, ref) => (
  <SelectPrimitive.Trigger
    ref={ref}
    className={trigger({ className })}
    disabled={isLoading}
    {...props}
  >
    {children}
    <SelectPrimitive.Icon asChild>
      {isLoading ? (
        <Loader className="size-4" />
      ) : (
        <AltArrowDownIcon className="my-0.5 size-4 shrink-0 cursor-pointer fill-brown-09 stroke-brown-09 stroke-1 duration-150" />
      )}
    </SelectPrimitive.Icon>
  </SelectPrimitive.Trigger>
));
Trigger.displayName = SelectPrimitive.Trigger.displayName;

const ScrollUpButton = forwardRef<
  ElementRef<typeof SelectPrimitive.ScrollUpButton>,
  ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollUpButton>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.ScrollUpButton
    ref={ref}
    className={scrollButton({ className })}
    {...props}
  >
    <AltArrowUpIcon className="size-4" />
  </SelectPrimitive.ScrollUpButton>
));
ScrollUpButton.displayName = SelectPrimitive.ScrollUpButton.displayName;

const ScrollDownButton = forwardRef<
  ElementRef<typeof SelectPrimitive.ScrollDownButton>,
  ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollDownButton>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.ScrollDownButton
    ref={ref}
    className={scrollButton({ className })}
    {...props}
  >
    <AltArrowDownIcon className="size-4" />
  </SelectPrimitive.ScrollDownButton>
));
ScrollDownButton.displayName = SelectPrimitive.ScrollDownButton.displayName;

const Content = forwardRef<
  ElementRef<typeof SelectPrimitive.Content>,
  ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>(({ className, children, position = "popper", ...props }, ref) => (
  <SelectPrimitive.Portal>
    <SelectPrimitive.Content
      ref={ref}
      className={content({ position, className })}
      position={position}
      {...props}
    >
      <ScrollUpButton />
      <SelectPrimitive.Viewport className={viewport({ position })}>
        {children}
      </SelectPrimitive.Viewport>
      <ScrollDownButton />
    </SelectPrimitive.Content>
  </SelectPrimitive.Portal>
));
Content.displayName = SelectPrimitive.Content.displayName;

const Label = forwardRef<
  ElementRef<typeof SelectPrimitive.Label>,
  ComponentPropsWithoutRef<typeof SelectPrimitive.Label>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Label
    ref={ref}
    className={label({ className })}
    {...props}
  />
));
Label.displayName = SelectPrimitive.Label.displayName;

const Item = forwardRef<
  ElementRef<typeof SelectPrimitive.Item>,
  ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Item ref={ref} className={item({ className })} {...props}>
    <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>

    <SelectPrimitive.ItemIndicator className="absolute right-2.5">
      <CheckIcon className={itemSelectedIcon()} />
    </SelectPrimitive.ItemIndicator>
  </SelectPrimitive.Item>
));
Item.displayName = SelectPrimitive.Item.displayName;

const SelectSeparator = forwardRef<
  ElementRef<typeof SelectPrimitive.Separator>,
  ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Separator
    ref={ref}
    className={separator({ className })}
    {...props}
  />
));
SelectSeparator.displayName = SelectPrimitive.Separator.displayName;

export {
  Root,
  Group,
  Value,
  Trigger,
  Content,
  Label,
  Item,
  SelectSeparator,
  ScrollUpButton,
  ScrollDownButton,
};
