import { DetailCard } from ".";
import { Skeleton } from "../Skeleton";

export const DetailCardSkeleton = () => {
  return (
    <DetailCard.Root color="salmon">
      <DetailCard.Header>
        <Skeleton className="size-4" />
        <div className="flex w-full flex-col gap-1">
          <Skeleton className="mt-0.5 h-4 w-full" />
          <Skeleton className="mt-0.5 h-4 w-1/2" />
        </div>
      </DetailCard.Header>

      <DetailCard.Content>
        <div className="mt-auto flex grow flex-wrap gap-2 sm:flex-nowrap">
          <Skeleton className="h-5 w-full rounded-3xl border border-salmon-04" />
          <Skeleton className="h-5 w-full rounded-3xl border border-salmon-04 bg-transparent" />
        </div>

        <div className="flex flex-row gap-4">
          <div className="flex flex-1 items-center gap-1">
            <Skeleton className="size-3.5" />
            <Skeleton className="h-3.5 w-14" />
          </div>

          <div className="flex flex-1 items-center justify-end gap-1">
            <Skeleton className="h-3.5 w-11" />
            <Skeleton className="size-3.5" />
          </div>
        </div>
      </DetailCard.Content>

      <DetailCard.Separator />

      <DetailCard.Footer>
        <div className="flex items-center gap-1">
          <Skeleton className="size-3.5" />
          <Skeleton className="h-3.5 w-14" />
        </div>

        <div className="flex gap-1">
          <Skeleton className="size-7" />
          <Skeleton className="size-7" />
        </div>
      </DetailCard.Footer>
    </DetailCard.Root>
  );
};
