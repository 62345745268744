import { t } from "ttag";

import { usePrefetchPlans } from "@/domains/billing/hooks";
import { PLAN_DURATION } from "@/domains/billing/models";
import { useOnboardingStore } from "@/stores";
import { Badge, Tabs } from "@/ui";
import { OnboardingPatientPlans } from "./components";

export const SubscriptionContent = () => {
  const setSubscriptionPlan = useOnboardingStore(
    (state) => state.setSubscriptionPlan,
  );
  const couponCode = useOnboardingStore((state) => state.limitedCouponCode);

  const { prefetchOnboardingPlans } = usePrefetchPlans();

  return (
    <Tabs.Root
      defaultValue={PLAN_DURATION.MONTHLY.TEXT_VALUE}
      onValueChange={() => setSubscriptionPlan(null)}
      variant="secondary"
    >
      <Tabs.List>
        <Tabs.Trigger
          value={PLAN_DURATION.MONTHLY.TEXT_VALUE}
          className="flex-col"
          onMouseEnter={() =>
            prefetchOnboardingPlans({
              durationInMonths: PLAN_DURATION.MONTHLY.VALUE,
              couponCode,
            })
          }
        >
          {t`Month-to-month`}
        </Tabs.Trigger>

        <Tabs.Trigger
          value={PLAN_DURATION.QUARTERLY.TEXT_VALUE}
          className="relative flex-wrap gap-2"
          onMouseEnter={() =>
            prefetchOnboardingPlans({
              durationInMonths: PLAN_DURATION.QUARTERLY.VALUE,
              couponCode,
            })
          }
        >
          {t`Three month`}
          <Badge
            size="sm"
            rounded="3xl"
            className="absolute right-0 top-0 -translate-y-4.5 translate-x-3 bg-nature-08 text-salmon-01 lg:relative lg:translate-x-0 lg:translate-y-0"
          >
            {t`Best value!`}
          </Badge>
        </Tabs.Trigger>
      </Tabs.List>

      <div className="grow lg:py-4">
        <Tabs.Content
          value={PLAN_DURATION.MONTHLY.TEXT_VALUE}
          className="flex flex-col gap-6"
        >
          <p className="pt-2 text-center text-sm">
            {t`Unlock exclusive benefits with our easy-access subscription. Join today and start enjoying the perks!`}
          </p>
          <OnboardingPatientPlans
            durationInMonths={PLAN_DURATION.MONTHLY.VALUE}
          />
        </Tabs.Content>

        <Tabs.Content
          value={PLAN_DURATION.QUARTERLY.TEXT_VALUE}
          className="flex flex-col gap-6"
        >
          <p className="pt-2 text-center text-sm">
            <strong>{t`Cover all 3 months of doses with a single order!`}</strong>
            {t` No fuss—place your order and check in with us monthly to track your progress.`}
          </p>
          <OnboardingPatientPlans
            durationInMonths={PLAN_DURATION.QUARTERLY.VALUE}
          />
        </Tabs.Content>
      </div>
    </Tabs.Root>
  );
};
