import { format } from "date-fns";
import { t } from "ttag";

import { SESSION_COLOR_MAP, SESSION_ICON_MAP } from "@/domains/coaching/models";
import type { AdminSession, Session } from "@/domains/coaching/models";
import {
  Button,
  ClockIcon,
  DetailCard,
  IconWrapper,
  UserCircleIcon,
  UsersGroupTwoRoundedIcon,
  VideoCameraIcon,
} from "@/ui";

type PastSessionCardProps =
  | {
      admin: true;
      session: AdminSession;
    }
  | {
      admin?: false;
      session: Session;
    };

export const PastSessionCard = ({ admin, session }: PastSessionCardProps) => {
  return (
    <DetailCard.Root color={SESSION_COLOR_MAP[session.category]}>
      <DetailCard.Header>
        <IconWrapper size="sm">
          {SESSION_ICON_MAP[session.category]}
        </IconWrapper>
        <DetailCard.Title className="line-clamp-2">
          {session.title}
        </DetailCard.Title>
      </DetailCard.Header>

      <DetailCard.Content>
        <div className="mt-auto flex flex-wrap gap-2 sm:flex-nowrap">
          <DetailCard.Badge variant="outline" rounded="lg">
            {t`Streamed on`} {format(session.startAt, "MM/dd/yy")}
          </DetailCard.Badge>
        </div>

        <div className="flex flex-row gap-4">
          <div className="flex min-w-0 flex-1 items-center gap-1">
            <UserCircleIcon className="size-3.5" />
            <DetailCard.Label>{t`Coach`}</DetailCard.Label>
            <DetailCard.Description className="truncate">
              {session.coach.name}
            </DetailCard.Description>
          </div>

          <div className="flex items-center gap-1">
            <DetailCard.Description>{session.duration}</DetailCard.Description>
            <DetailCard.Label>{t`min`}</DetailCard.Label>
            <ClockIcon className="size-3.5" />
          </div>
        </div>
      </DetailCard.Content>

      <DetailCard.Separator />

      {admin && (
        <DetailCard.Footer>
          <div className="flex items-center gap-1">
            <UsersGroupTwoRoundedIcon className="size-3.5" />
            <DetailCard.Description>
              {t`${session.enrolledUsers} users`}
            </DetailCard.Description>
          </div>

          <div className="flex gap-1">
            {session.recordingUrl ? (
              <Button
                size="xs"
                color="brown"
                className="w-fit text-nature-08"
                asChild
              >
                <a href={session.recordingUrl} target="_blank" rel="noreferrer">
                  {t`Recording`}
                  <VideoCameraIcon className="size-3" />
                </a>
              </Button>
            ) : (
              <DetailCard.Description className="mx-auto inline-flex items-center gap-1 border-y border-transparent py-1 text-brown-06">
                {t`The recording is processing`}
                <ClockIcon className="size-3" />
              </DetailCard.Description>
            )}
          </div>
        </DetailCard.Footer>
      )}

      {!admin && (
        <DetailCard.Footer>
          {session.recordingUrl ? (
            <Button
              size="xs"
              color="brown"
              className="w-full text-nature-08"
              asChild
            >
              <a href={session.recordingUrl} target="_blank" rel="noreferrer">
                {t`View recording`}
                <VideoCameraIcon className="size-3" />
              </a>
            </Button>
          ) : (
            <DetailCard.Description className="mx-auto inline-flex items-center gap-1 border-y border-transparent py-1 text-brown-06">
              {t`The recording is processing`}
              <ClockIcon className="size-3" />
            </DetailCard.Description>
          )}
        </DetailCard.Footer>
      )}
    </DetailCard.Root>
  );
};
