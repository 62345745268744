import { useMutation, useQueryClient } from "@tanstack/react-query";

import { MEDICATIONS_DOMAIN, PORTAL_DOMAIN } from "@/api";
import { errorToast } from "@/ui";
import { updateMedicationRequestStatus } from "../api";

export const useUpdateMedicationStatus = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateMedicationRequestStatus,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [MEDICATIONS_DOMAIN] });
      void queryClient.invalidateQueries({ queryKey: [PORTAL_DOMAIN] });
    },
    onError: errorToast,
  });
};
