import { t } from "ttag";

import type { SubscriptionStatus } from "@/domains/billing/models";
import { SUBSCRIPTION_STATUS } from "@/domains/billing/models";
import { ROUTES } from "@/router";
import type { DomainRoute } from "@/shared.types";
import { getEnumValues } from "@/utils";

interface NavigationItem {
  path: DomainRoute;
  label: string;
  subscriptionStatus: SubscriptionStatus[];
  needsIntakeForm?: boolean;
  disabled?: boolean;
  comingSoon?: boolean;
}

const allSubscriptions = getEnumValues(SUBSCRIPTION_STATUS);

export const getAdminNavigation = () =>
  [
    {
      path: ROUTES.COACHING.OVERVIEW,
      label: t`Coaching Lab`,
      subscriptionStatus: allSubscriptions,
    },
  ] as NavigationItem[];

export const getCoachingNavigation = () =>
  [
    {
      path: ROUTES.COACHING.OVERVIEW,
      label: t`Coaching Hub`,
      subscriptionStatus: [SUBSCRIPTION_STATUS.ACTIVE],
    },
    {
      path: ROUTES.MESSAGES,
      label: t`Messages`,
      subscriptionStatus: allSubscriptions,
    },
    {
      path: ROUTES.BILLING.OVERVIEW,
      label: t`Billing`,
      subscriptionStatus: allSubscriptions,
    },
  ] as NavigationItem[];

export const getPatientNavigation = () =>
  [
    {
      path: ROUTES.HOME,
      label: t`Home`,
      subscriptionStatus: allSubscriptions,
    },
    {
      path: ROUTES.MY_MEDS,
      label: t`My Meds`,
      subscriptionStatus: [
        SUBSCRIPTION_STATUS.ACTIVE,
        SUBSCRIPTION_STATUS.PAID_IN_HEALTHIE,
        SUBSCRIPTION_STATUS.EMBEDDABLES,
      ],
      needsIntakeForm: true,
    },
    {
      path: ROUTES.CONSULTATIONS.OVERVIEW,
      label: t`Consultations`,
      subscriptionStatus: [
        SUBSCRIPTION_STATUS.ACTIVE,
        SUBSCRIPTION_STATUS.PAID_IN_HEALTHIE,
        SUBSCRIPTION_STATUS.EMBEDDABLES,
      ],
      needsIntakeForm: true,
    },
    {
      path: ROUTES.COACHING.OVERVIEW,
      label: t`Coaching Hub`,
      subscriptionStatus: [
        SUBSCRIPTION_STATUS.ACTIVE,
        SUBSCRIPTION_STATUS.PAID_IN_HEALTHIE,
        SUBSCRIPTION_STATUS.EMBEDDABLES,
      ],
    },
    {
      path: ROUTES.DOCUMENT_CENTER.OVERVIEW,
      label: t`Document center`,
      subscriptionStatus: allSubscriptions,
      needsIntakeForm: false,
    },
    {
      path: ROUTES.MESSAGES,
      label: t`Messages`,
      subscriptionStatus: allSubscriptions,
    },
    {
      path: ROUTES.BILLING.OVERVIEW,
      label: t`Billing`,
      subscriptionStatus: allSubscriptions,
    },
  ] as NavigationItem[];
