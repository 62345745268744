import { CreateSessionButton } from "@/domains/coaching/components";
import { AdminScreenContentLayout } from "@/domains/coaching/layouts";
import {
  CoachingLabHeader,
  CoachingLabTabs,
} from "@/domains/coaching/sections";

export const CoachingLab = () => {
  return (
    <AdminScreenContentLayout>
      <CoachingLabHeader />

      <CoachingLabTabs />

      <CreateSessionButton />
    </AdminScreenContentLayout>
  );
};
