import type { PropsWithChildren } from "react";
import { createContext, useContext } from "react";

import { useEditSessionForm } from "../hooks";

interface EditSessionContextProps {
  sessionForm: ReturnType<typeof useEditSessionForm>;
}

export const EditSessionContext = createContext<EditSessionContextProps | null>(
  null,
);

export const EditSessionContextProvider = ({ children }: PropsWithChildren) => {
  const sessionForm = useEditSessionForm();

  return (
    <EditSessionContext.Provider value={{ sessionForm }}>
      {children}
    </EditSessionContext.Provider>
  );
};

export const useEditSessionContext = () => {
  const context = useContext(EditSessionContext);

  if (!context) {
    throw new Error(
      "useEditSessionContext must be used within a <EditSessionContextProvider />",
    );
  }

  return context;
};
