import type { ComponentPropsWithoutRef, ElementRef } from "react";
import { forwardRef } from "react";

import { tw } from "@/utils";
import { LightbulbFixedIconMono } from "../illustrated-icons";

const Root = forwardRef<ElementRef<"div">, ComponentPropsWithoutRef<"div">>(
  ({ className, ...props }, ref) => (
    <section
      ref={ref}
      className={tw(
        "flex grow flex-col items-center justify-center gap-2 rounded-2xl border border-nature-06 p-4 md:p-6",

        className,
      )}
      {...props}
    />
  ),
);
Root.displayName = "EmptyState.Root";

const Icon = forwardRef<ElementRef<"div">, ComponentPropsWithoutRef<"div">>(
  ({ className, children, ...props }, ref) => (
    <div
      ref={ref}
      className={tw("size-20 text-brown-05", className)}
      {...props}
    >
      {children ? children : <LightbulbFixedIconMono variant="secondary" />}
    </div>
  ),
);
Icon.displayName = "EmptyState.Icon";

const Title = forwardRef<ElementRef<"h3">, ComponentPropsWithoutRef<"h3">>(
  ({ className, children, ...props }, ref) => (
    <h3
      ref={ref}
      className={tw("text-center text-lg font-bold text-brown-10", className)}
      {...props}
    >
      {children}
    </h3>
  ),
);
Title.displayName = "EmptyState.Title";

const Description = forwardRef<ElementRef<"p">, ComponentPropsWithoutRef<"p">>(
  ({ className, ...props }, ref) => (
    <p
      ref={ref}
      className={tw("text-center text-lg font-medium text-brown-05", className)}
      {...props}
    />
  ),
);
Description.displayName = "EmptyState.Description";

export { Root, Title, Description, Icon };
