import typography from "@tailwindcss/typography";
import type { Config } from "tailwindcss";
import animate from "tailwindcss-animate";

export default {
  content: ["./resources/**/*.{js,ts,jsx,tsx}"],
  plugins: [typography, animate],
  theme: {
    fontFamily: {
      sans: ["'DM Sans'", "sans-serif"],
      serif: ["'DM Serif Display'", "serif"],
    },
    extend: {
      spacing: {
        4.5: "1.125rem",
        42: "10.5rem",
        67: "16.75rem",
        112: "28rem",
        138: "34.5rem",
        152: "38rem",
        190: "47.5rem",
        219: "54.75rem",
        modal: "37.5rem",
        "alert-dialog": "33rem",
        "subscription-card": "22rem",
      },
      width: { desktop: "1440px" },
      maxWidth: { desktop: "1440px" },
      minWidth: { desktop: "1440px" },
      height: { desktop: "1024px" },
      maxHeight: { desktop: "1024px" },
      minHeight: { desktop: "1024px" },
      lineHeight: {
        4.5: "1.125rem",
      },
      boxShadow: {
        "modal-footer": "0 -2px 10px 0px rgba(115,115,115,0.15)",
      },
      colors: {
        brown: {
          "01": "#F9F7F6",
          "02": "#F5ECE3",
          "03": "#F1E1D2",
          "04": "#DDCEC0",
          "05": "#BEAC9C",
          "06": "#A59383",
          "07": "#8B7B6C",
          "08": "#726355",
          "09": "#594C40",
          10: "#3F352C",
        },
        nature: {
          "01": "#F6F7F6",
          "02": "#EBF5E8",
          "03": "#D3DECE",
          "04": "#BAC7B6",
          "05": "#A3B19E",
          "06": "#8C9A87",
          "07": "#768371",
          "08": "#606D5B",
          "09": "#4B5647",
          10: "#363F33",
        },
        salmon: {
          "01": "#FFFAF8",
          "02": "#FFEDE3",
          "03": "#FFDFCD",
          "04": "#FFD2B8",
          "05": "#E3B79E",
          "06": "#C69C84",
          "07": "#A9826C",
          "08": "#8C6955",
          "09": "#6F5140",
          10: "#523A2D",
        },
        green: {
          "01": "#DCFCE7",
          "02": "#BBF7D0",
          "03": "#86EFAC",
          "04": "#4ADE80",
          "05": "#22C55E",
          "06": "#16A34A",
          "07": "#15803D",
          "08": "#166534",
          "09": "#14532D",
          10: "#052E16",
        },
        yellow: {
          "01": "#FEF9C3",
          "02": "#FEE58A",
          "03": "#FDE047",
          "04": "#FACC15",
          "05": "#EAB308",
          "06": "#CA8A04",
          "07": "#A16207",
          "08": "#854D0E",
          "09": "#713F12",
          10: "#422006",
        },
        red: {
          "01": "#FEE2E2",
          "02": "#FECACA",
          "03": "#FCA5A5",
          "04": "#F87171",
          "05": "#EF4444",
          "06": "#DC2626",
          "07": "#B91C1C",
          "08": "#991B1B",
          "09": "#7F1D1D",
          10: "#450A0A",
        },
      },
      keyframes: {
        "accordion-down": {
          from: { height: "0" },
          to: { height: "var(--radix-accordion-content-height)" },
        },
        "accordion-up": {
          from: { height: "var(--radix-accordion-content-height)" },
          to: { height: "0" },
        },
      },
      animation: {
        "accordion-down": "accordion-down 0.2s ease-out",
        "accordion-up": "accordion-up 0.2s ease-out",
      },
    },
  },
} satisfies Config;
