import { createQueryKeyStore } from "@lukemorales/query-key-factory";
import { skipToken } from "@tanstack/react-query";

import type { AdminSession } from "../models";
import { getAdminSession, getAdminSessions } from "./admin-sessions";
import { getCoaches } from "./coaches";
import { getSessions, getUpcomingSessions } from "./sessions";

export const coachingQueriesStore = createQueryKeyStore({
  coaching: {
    admin: {
      queryKey: null,
      contextQueries: {
        session: {
          queryKey: null,
          contextQueries: {
            detail: (sessionId?: AdminSession["id"]) => ({
              queryKey: [sessionId],
              queryFn: sessionId
                ? () => getAdminSession(sessionId)
                : (skipToken as never),
            }),

            list: (params: Parameters<typeof getAdminSessions>[0]) => ({
              queryKey: [{ params }],
              queryFn: () => getAdminSessions(params),
            }),
          },
        },

        coach: {
          queryKey: null,
          contextQueries: {
            list: {
              queryKey: null,
              queryFn: getCoaches,
            },
          },
        },
      },
    },

    patient: {
      queryKey: null,
      contextQueries: {
        session: {
          queryKey: null,
          contextQueries: {
            list: (params: Parameters<typeof getSessions>[0]) => ({
              queryKey: [{ params }],
              queryFn: () => getSessions(params),
            }),
            upcoming: {
              queryKey: null,
              queryFn: getUpcomingSessions,
            },
          },
        },
      },
    },
  },
});
