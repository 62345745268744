import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import type { SubscriptionStatus } from "@/domains/billing/models";
import { SUBSCRIPTION_STATUS } from "@/domains/billing/models";
import { useBoolean } from "@/hooks";
import { CancelSubscriptionModal, ReactivateSubscriptionModal } from "@/modals";
import { ROUTES } from "@/router";
import { PORTAL_USER_TYPE } from "@/shared";
import { usePortalUserStore } from "@/stores";
import { Button, DollarMinimalisticIcon, IconWrapper } from "@/ui";

interface SubscriptionActionsProps {
  status: SubscriptionStatus;
}

export const SubscriptionActions = ({ status }: SubscriptionActionsProps) => {
  const portalUserType = usePortalUserStore((state) => state.portalUser?.type);

  const navigate = useNavigate();

  const {
    value: showCancelSubscriptionModal,
    setTrue: openCancelSubscriptionModal,
    setFalse: closeCancelSubscriptionModal,
  } = useBoolean(false);
  const {
    value: showReactivateSubscriptionModal,
    setTrue: openReactivateSubscriptionModal,
    setFalse: closeReactivateSubscriptionModal,
  } = useBoolean(false);

  const handleChangeSubscription = () => {
    if (portalUserType === PORTAL_USER_TYPE.PATIENT) {
      navigate(ROUTES.BILLING.CHANGE_SUBSCRIPTION);
    } else {
      navigate(ROUTES.MESSAGES);
    }
  };

  if (status === SUBSCRIPTION_STATUS.CANCELLED) {
    return (
      <>
        <Button
          onClick={openReactivateSubscriptionModal}
          size="lg"
          className="w-full shrink"
        >
          <IconWrapper size="md">
            <DollarMinimalisticIcon />
          </IconWrapper>
          {t`Reactivate Subscription`}
        </Button>

        <ReactivateSubscriptionModal
          show={showReactivateSubscriptionModal}
          onClose={closeReactivateSubscriptionModal}
        />
      </>
    );
  }

  const hasPaymentError = status === SUBSCRIPTION_STATUS.PAYMENT_FAILED;

  return (
    <>
      <Button
        disabled={hasPaymentError}
        onClick={openCancelSubscriptionModal}
        size="lg"
        variant="secondary"
        className="w-full shrink"
      >
        {t`Cancel subscription`}
      </Button>

      <Button
        disabled={hasPaymentError}
        onClick={handleChangeSubscription}
        size="lg"
        className="w-full shrink"
      >
        <IconWrapper size="md">
          <DollarMinimalisticIcon />
        </IconWrapper>
        {t`Change subscription`}
      </Button>

      <CancelSubscriptionModal
        show={showCancelSubscriptionModal}
        onClose={closeCancelSubscriptionModal}
      />
    </>
  );
};
