import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { t } from "ttag";

import { ROUTES } from "@/router";
import { errorToast, useToastStore } from "@/ui";
import { coachingQueriesStore, deleteSession } from "../api";

export const useDeleteSession = () => {
  const pushToast = useToastStore((state) => state.pushToast);

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteSession,
    onSuccess: () => {
      void pushToast({
        type: "success",
        title: t`Success`,
        message: t`Session successfully deleted!`,
      });

      void queryClient.invalidateQueries({
        queryKey: coachingQueriesStore.coaching.admin._ctx.session.queryKey,
      });

      if (pathname !== ROUTES.COACHING.OVERVIEW) {
        navigate(ROUTES.COACHING.OVERVIEW);
      }
    },
    onError: errorToast,
  });
};
