import type { ComponentPropsWithoutRef, ElementRef } from "react";
import { forwardRef } from "react";
import * as RadioGroup from "@radix-ui/react-radio-group";
import { tv } from "tailwind-variants";

import { Badge } from "@/ui";
import { tw } from "@/utils";

const pricingCardVariants = tv({
  slots: {
    root: "flex max-w-subscription-card flex-col overflow-hidden rounded-lg border border-transparent bg-white text-brown-08",
    radioItem:
      "cursor-pointer disabled:cursor-not-allowed disabled:opacity-70 data-[state=checked]:border-nature-07 data-[state=checked]:bg-nature-02 data-[state=checked]:text-nature-08 [&_.discount-alert]:data-[state=checked]:bg-nature-03 [&_.discount-alert_strong]:data-[state=checked]:text-nature-10 [&_em]:data-[state=checked]:text-nature-06 [&_label]:data-[state=checked]:text-nature-08",
    thumbnail: "size-full h-60 bg-salmon-01 object-cover",
    container: "relative flex w-full flex-col items-start gap-2 p-6",
    badgeContainer:
      "absolute left-1/2 top-0 w-full -translate-x-1/2 -translate-y-1/2",
    badgeItem:
      "mx-auto items-center gap-1 border-yellow-05 bg-yellow-04 font-medium text-brown-10",
    subtitle: "-mb-1 font-bold text-nature-09 md:h-5",
    title: "text-left text-2xl font-bold text-nature-10",
    description: "text-left text-sm text-brown-08 md:min-h-5",
    previousPrice: "pr-2 text-3xl font-bold text-brown-06 line-through",
    price: "text-5xl text-brown-10",
    priceDetails: "text-sm",
    disclaimer: "text-start text-sm text-brown-10",
    list: "list-inside list-disc pl-1.5 text-left text-sm [&_em]:not-italic [&_em]:text-brown-06",
    discountAlert:
      "discount-alert rounded-2xl bg-brown-03 p-3 text-left text-sm [&>strong]:text-yellow-10",
    footer: "flex grow flex-col items-start gap-2",
  },
  variants: {
    hasDurationInMonths: {
      true: {
        priceDetails: "block",
      },
    },
    hasDiscount: {
      false: {
        disclaimer: "-translate-y-1",
      },
    },
  },
});

const {
  root,
  radioItem,
  thumbnail,
  container,
  badgeContainer,
  badgeItem,
  subtitle,
  title,
  description,
  previousPrice,
  price,
  disclaimer,
  list,
  priceDetails,
  discountAlert,
  footer,
} = pricingCardVariants();

const Root = forwardRef<
  ElementRef<typeof RadioGroup.Item>,
  ComponentPropsWithoutRef<typeof RadioGroup.Item>
>(({ className, ...props }, ref) => {
  return (
    <RadioGroup.Item
      ref={ref}
      className={tw(root({ className }), radioItem())}
      {...props}
    />
  );
});
Root.displayName = "PricingCard.Root";

const Thumbnail = forwardRef<
  ElementRef<"img">,
  ComponentPropsWithoutRef<"img">
>(({ alt, className, ...props }, ref) => {
  return (
    <img
      ref={ref}
      alt={alt}
      className={thumbnail({ className })}
      draggable="false"
      {...props}
    />
  );
});
Thumbnail.displayName = "PricingCard.Thumbnail";

const Container = forwardRef<
  ElementRef<"div">,
  ComponentPropsWithoutRef<"div">
>(({ className, ...props }, ref) => {
  return <div ref={ref} className={container({ className })} {...props} />;
});
Container.displayName = "PricingCard.Container";

const BadgeContainer = forwardRef<
  ElementRef<"div">,
  ComponentPropsWithoutRef<"div">
>(({ className, ...props }, ref) => {
  return <div ref={ref} className={badgeContainer({ className })} {...props} />;
});
BadgeContainer.displayName = "PricingCard.BadgeContainer";

const BadgeItem = forwardRef<
  ElementRef<typeof Badge>,
  ComponentPropsWithoutRef<typeof Badge>
>(({ className, ...props }, ref) => {
  return (
    <Badge
      ref={ref}
      rounded="3xl"
      className={badgeItem({ className })}
      {...props}
    />
  );
});
BadgeItem.displayName = "PricingCard.BadgeItem";

const Subtitle = forwardRef<ElementRef<"p">, ComponentPropsWithoutRef<"p">>(
  ({ className, ...props }, ref) => {
    return <p ref={ref} className={subtitle({ className })} {...props} />;
  },
);
Subtitle.displayName = "PricingCard.Subtitle";

const Title = forwardRef<ElementRef<"h4">, ComponentPropsWithoutRef<"h4">>(
  ({ className, children, ...props }, ref) => {
    return (
      <h4 ref={ref} className={title({ className })} {...props}>
        {children}
      </h4>
    );
  },
);
Title.displayName = "PricingCard.Title";

const Description = forwardRef<ElementRef<"p">, ComponentPropsWithoutRef<"p">>(
  ({ className, ...props }, ref) => {
    return <p ref={ref} className={description({ className })} {...props} />;
  },
);
Description.displayName = "PricingCard.Description";

const PreviousPrice = forwardRef<
  ElementRef<"span">,
  ComponentPropsWithoutRef<"span">
>(({ className, ...props }, ref) => {
  return <span ref={ref} className={previousPrice({ className })} {...props} />;
});
PreviousPrice.displayName = "PricingCard.PreviousPrice";

const Price = forwardRef<
  ElementRef<"strong">,
  ComponentPropsWithoutRef<"strong">
>(({ className, ...props }, ref) => {
  return <strong ref={ref} className={price({ className })} {...props} />;
});
Price.displayName = "PricingCard.Price";

const PriceDetails = forwardRef<
  ElementRef<"span">,
  ComponentPropsWithoutRef<"span"> & { hasDurationInMonths: boolean }
>(({ hasDurationInMonths, className, ...props }, ref) => {
  return (
    <span
      ref={ref}
      className={priceDetails({ hasDurationInMonths, className })}
      {...props}
    />
  );
});
PriceDetails.displayName = "PricingCard.PriceDetails";

const Disclaimer = forwardRef<
  ElementRef<"p">,
  ComponentPropsWithoutRef<"p"> & { hasDiscount: boolean }
>(({ hasDiscount, className, ...props }, ref) => {
  return (
    <p
      ref={ref}
      className={disclaimer({ hasDiscount, className })}
      {...props}
    />
  );
});
Disclaimer.displayName = "PricingCard.Disclaimer";

const DiscountAlert = forwardRef<
  ElementRef<"div">,
  ComponentPropsWithoutRef<"div">
>(({ className, ...props }, ref) => {
  return <div ref={ref} className={discountAlert({ className })} {...props} />;
});
DiscountAlert.displayName = "PricingCard.DiscountAlert";

const Footer = forwardRef<ElementRef<"div">, ComponentPropsWithoutRef<"div">>(
  ({ className, ...props }, ref) => {
    return <div ref={ref} className={footer({ className })} {...props} />;
  },
);
Footer.displayName = "PricingCard.Footer";

const FeaturesList = forwardRef<
  ElementRef<"ul">,
  ComponentPropsWithoutRef<"ul">
>(({ className, ...props }, ref) => {
  return <ul ref={ref} className={list({ className })} {...props} />;
});
FeaturesList.displayName = "PricingCard.FeaturesList";

export const PricingCard = {
  Root,
  Thumbnail,
  Container,
  BadgeContainer,
  BadgeItem,
  Subtitle,
  Title,
  Description,
  PreviousPrice,
  Price,
  PriceDetails,
  Disclaimer,
  DiscountAlert,
  Footer,
  FeaturesList,
};
