import { useState } from "react";
import { t } from "ttag";

import { ErrorState } from "@/components";
import {
  CardsGrid,
  DeleteSessionModal,
  DraftSessionCard,
} from "@/domains/coaching/components";
import {
  useAdminSessions,
  useSessionCategory,
  useSessionStatus,
} from "@/domains/coaching/hooks";
import { SESSION_ICON_MAP } from "@/domains/coaching/models";
import type { AdminSession } from "@/domains/coaching/models";
import { useBoolean } from "@/hooks";
import { usePagination } from "@/shared/hooks";
import {
  CardPrimitive,
  DetailCardSkeleton,
  EmptyState,
  Pagination,
} from "@/ui";

export const DraftSessions = () => {
  const { category } = useSessionCategory();
  const { status } = useSessionStatus();

  const {
    page,
    pageSize,
    actions: { changePage },
  } = usePagination();

  const { data, isLoading, isSuccess, isError } = useAdminSessions({
    filter: { category, status },
    pagination: { page, pageSize },
  });
  const sessions = data?.data ?? [];
  const pagination = data?.pagination;

  const {
    value: showDeleteModal,
    setTrue: openDeleteModal,
    setFalse: closeDeleteModal,
  } = useBoolean(false);
  const [sessionToDelete, setSessionToDelete] = useState<AdminSession>();

  const handleClickDelete = (session: AdminSession) => {
    setSessionToDelete(session);
    openDeleteModal();
  };

  const handleCloseDeleteModal = () => {
    setSessionToDelete(undefined);
    closeDeleteModal();
  };

  const categoryText = category ?? "";

  return (
    <CardPrimitive.Root className="h-full gap-4 bg-brown-02">
      {isLoading && (
        <CardsGrid>
          {Array.from({ length: 4 }).map((_, index) => (
            <DetailCardSkeleton key={`detail-card-skeleton-${index}`} />
          ))}
        </CardsGrid>
      )}

      {Boolean(isSuccess && sessions.length) && (
        <CardsGrid>
          {sessions.map((session) => (
            <DraftSessionCard
              key={session.id}
              session={session}
              onDelete={handleClickDelete}
            />
          ))}
        </CardsGrid>
      )}

      {isSuccess && !sessions.length && (
        <EmptyState.Root className="justify-self-center border-transparent">
          <EmptyState.Icon>
            {category && SESSION_ICON_MAP[category]}
          </EmptyState.Icon>
          <EmptyState.Description>
            {t`There are no draft ${categoryText} sessions yet`}
          </EmptyState.Description>
        </EmptyState.Root>
      )}

      {isError && (
        <ErrorState className="justify-self-center border-transparent" />
      )}

      {pagination && pagination.totalPages > 1 && (
        <div className="flex justify-center border-t border-brown-04 pt-4 md:justify-end">
          <Pagination {...pagination} onPageChange={changePage} />
        </div>
      )}

      {sessionToDelete && (
        <DeleteSessionModal
          show={Boolean(showDeleteModal && sessionToDelete)}
          onClose={handleCloseDeleteModal}
          session={sessionToDelete}
        />
      )}
    </CardPrimitive.Root>
  );
};
