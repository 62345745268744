import { useQuery } from "@tanstack/react-query";

import { coachingQueriesStore } from "../api";
import type { AdminSession } from "../models";

export const useAdminSession = (id?: AdminSession["id"]) => {
  return useQuery(
    coachingQueriesStore.coaching.admin._ctx.session._ctx.detail(id),
  );
};
