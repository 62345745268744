import type { UseFormRegister } from "react-hook-form";
import { t } from "ttag";

import type { DateOfBirthValues } from "@/shared.types";
import { Input } from "./Input";

type DOBRegister<T> = T extends {
  dateOfBirth: DateOfBirthValues;
}
  ? UseFormRegister<T>
  : never;

interface DOBFieldProps<
  T extends {
    dateOfBirth: DateOfBirthValues;
  },
> {
  register: DOBRegister<T>;
}

export const DateOfBirthInputs = <
  T extends {
    dateOfBirth: DateOfBirthValues;
  },
>({
  register,
}: DOBFieldProps<T>) => {
  return (
    <div className="grid grid-cols-3 gap-x-1.5">
      <Input
        type="number"
        step={1}
        placeholder={t`MM`}
        autoComplete="bday-month"
        {...register("dateOfBirth.month", { setValueAs: Number })}
      />
      <Input
        type="number"
        step={1}
        placeholder={t`DD`}
        autoComplete="bday-day"
        {...register("dateOfBirth.day", { setValueAs: Number })}
      />
      <Input
        type="number"
        step={1}
        placeholder={t`YYYY`}
        autoComplete="bday-year"
        {...register("dateOfBirth.year", { setValueAs: Number })}
      />
    </div>
  );
};
