import { t } from "ttag";

import { CreateSessionButton } from "@/domains/coaching/components";
import { ROUTES } from "@/router";
import { Header, LightbulbIcon } from "@/ui";

export const CoachingLabHeader = () => {
  return (
    <Header.Root>
      <Header.Background />

      <div className="flex gap-6">
        <Header.Content className="grow">
          <Header.Breadcrumb
            links={[
              {
                href: ROUTES.COACHING.OVERVIEW,
                icon: LightbulbIcon,
                label: t`Coaching lab`,
              },
            ]}
          />

          <Header.Title>{t`Coaching lab`}</Header.Title>
        </Header.Content>

        <Header.Actions className="mt-auto p-5">
          <CreateSessionButton position="header" />
        </Header.Actions>
      </div>
    </Header.Root>
  );
};
