import { t } from "ttag";

import {
  HandHeartIcon,
  MeditationRoundIcon,
  StarRainbowIcon,
  StarsIcon,
} from "@/ui";
import type { AdminSession } from "./types";

export const SESSION_TITLE_MAX_LENGTH = 200;

export const SESSION_STATUS = {
  UPCOMING: "upcoming",
  PAST: "past",
} as const;

export const ADMIN_SESSION_STATUS = {
  ...SESSION_STATUS,
  DRAFT: "draft",
} as const;

export const SESSION_CATEGORY = {
  NUTRITION: "Nutrition",
  MINDSET: "Mindset",
  MOVEMENT: "Movement Flow",
  GROUP: "Support Group",
} as const;

export const BLOCK_SUBSCRIPTION_REASON = {
  ALREADY_SUBSCRIBED: "already_subscribed",
  SESSION_PASSED: "session_passed",
} as const;

export const SESSION_ICON_MAP = {
  [SESSION_CATEGORY.NUTRITION]: <StarsIcon />,
  [SESSION_CATEGORY.MINDSET]: <StarRainbowIcon />,
  [SESSION_CATEGORY.MOVEMENT]: <MeditationRoundIcon />,
  [SESSION_CATEGORY.GROUP]: <HandHeartIcon />,
} as const;

export const SESSION_COLOR_MAP = {
  [SESSION_CATEGORY.NUTRITION]: "nature",
  [SESSION_CATEGORY.MINDSET]: "brown",
  [SESSION_CATEGORY.MOVEMENT]: "nature-dark",
  [SESSION_CATEGORY.GROUP]: "salmon",
} as const;

export const getSessionStatusLabel = (status: AdminSession["status"]) => {
  return {
    [ADMIN_SESSION_STATUS.UPCOMING]: t`Upcoming`,
    [ADMIN_SESSION_STATUS.DRAFT]: t`Draft`,
    [ADMIN_SESSION_STATUS.PAST]: t`Past`,
  }[status];
};

export const getSessionCategoryLabel = (category: AdminSession["category"]) => {
  return {
    [SESSION_CATEGORY.NUTRITION]: t`Nutrition`,
    [SESSION_CATEGORY.MINDSET]: t`Mindset`,
    [SESSION_CATEGORY.MOVEMENT]: t`Movement flow`,
    [SESSION_CATEGORY.GROUP]: t`Support group`,
  }[category];
};
