import type { ComponentPropsWithoutRef } from "react";
import { t } from "ttag";

import { FullScreenLoader } from "@/components";
import { useBoolean } from "@/hooks";
import { Button, CheckCircleIcon, DisketteIcon, TrashBinIcon } from "@/ui";
import { useEditSessionContext } from "../context";
import { useUpdateSession } from "../hooks";
import { DeleteSessionModal } from "./DeleteSessionModal";
import type { ActionsVariants } from "./utils";
import { actionsVariants } from "./utils";

const DELETE_FIELDS = ["id", "enrolledUsers"] as const;

export const EditSessionActions = ({ position }: ActionsVariants) => {
  const {
    value: showDeleteModal,
    setTrue: openDeleteModal,
    setFalse: closeDeleteModal,
  } = useBoolean(false);
  const {
    sessionForm: {
      formState: { dirtyFields },
      handleSubmit,
      getValues,
      setError,
    },
  } = useEditSessionContext();

  const isDraft = !getValues("published");

  const updateSessionMutation = useUpdateSession(setError);

  const handleSave = handleSubmit((value) =>
    updateSessionMutation.mutate(value),
  );

  const handlePublish = handleSubmit((value) =>
    updateSessionMutation.mutate({ ...value, published: true }),
  );

  const sessionToDelete = Object.fromEntries(
    DELETE_FIELDS.map((field, index) => [
      field,
      getValues(DELETE_FIELDS)[index],
    ]),
  ) as ComponentPropsWithoutRef<typeof DeleteSessionModal>["session"];

  const isDirty = Object.keys(dirtyFields).length > 0;

  const { container, tertiaryButton } = actionsVariants({ position, isDraft });

  return (
    <div className={container()}>
      <Button
        onClick={openDeleteModal}
        variant={isDraft ? "tertiary" : "secondary"}
        color="red"
        size="lg"
        className={tertiaryButton()}
      >
        <TrashBinIcon className="size-4" />
        {t`Delete`}
      </Button>

      <Button
        onClick={handleSave}
        disabled={!isDirty}
        size="lg"
        variant={isDraft ? "secondary" : "primary"}
      >
        <DisketteIcon className="size-4" />
        {t`Save`}
      </Button>

      {isDraft && (
        <Button onClick={handlePublish} disabled={!isDirty} size="lg">
          <CheckCircleIcon className="size-4" />
          {t`Publish`}
        </Button>
      )}

      <DeleteSessionModal
        session={sessionToDelete}
        show={showDeleteModal}
        onClose={closeDeleteModal}
      />

      {updateSessionMutation.isPending && <FullScreenLoader />}
    </div>
  );
};
