import type { ComponentPropsWithoutRef, ElementRef } from "react";
import { forwardRef } from "react";
import { useMediaQuery } from "@uidotdev/usehooks";
import Markdown from "react-markdown";
import { jt, t } from "ttag";

import { PricingCard } from "@/components";
import type { Plan } from "@/domains/billing/models";
import { getPeriodDurationText } from "@/domains/billing/utils";
import {
  Accordion,
  AltArrowDownIcon,
  ClockIcon,
  Label,
  RadioIndicator,
  StarIcon,
} from "@/ui";

type SubscriptionItemProps = ComponentPropsWithoutRef<
  typeof PricingCard.Root
> & {
  plan: Plan;
  isPendingPlan?: boolean;
  isCurrentPlan?: boolean;
};

export const SubscriptionItem = forwardRef<
  ElementRef<typeof PricingCard.Root>,
  SubscriptionItemProps
>(({ plan, isCurrentPlan, isPendingPlan, className, ...props }, ref) => {
  const isMobile = useMediaQuery("(max-width: 847px)");

  const hasDurationInMonths = Boolean(plan.discount?.durationInMonths);
  const hasDiscount = Boolean(plan.discount);

  const durationInMonthsPeriod = plan.discount?.durationInMonths
    ? jt`for ${plan.discount.durationInMonths} month(s)`
    : "";

  const durationInMonths = plan.discount?.durationInMonths
    ? `${plan.discount.durationInMonths} `
    : "";

  const monthsDuration = getPeriodDurationText(plan.durationInMonths);

  const planPrice = (
    <strong key={`${plan.subscriptionPriceId}-initial-price`}>
      ${plan.price}
    </strong>
  );

  return (
    <PricingCard.Root ref={ref} className={className} {...props}>
      <PricingCard.Thumbnail
        src={plan.thumbnail}
        alt={`${plan.name} Thumbnail`}
      />

      <PricingCard.Container>
        <PricingCard.BadgeContainer>
          {isPendingPlan && (
            <PricingCard.BadgeItem className="border-yellow-05 bg-yellow-04 text-brown-10">
              <ClockIcon className="size-3" />
              {t`Pending`}
            </PricingCard.BadgeItem>
          )}

          {isCurrentPlan && (
            <PricingCard.BadgeItem className="border-salmon-09 bg-salmon-09 text-brown-01">
              <StarIcon className="size-3" />
              {t`Current subscription`}
            </PricingCard.BadgeItem>
          )}
        </PricingCard.BadgeContainer>

        <PricingCard.Subtitle>
          {plan.isCompound && t`Compounded`}
        </PricingCard.Subtitle>

        <div className="flex w-full justify-between gap-4">
          <PricingCard.Title>{plan.name}</PricingCard.Title>

          <RadioIndicator className="mt-1.5" size="md" />
        </div>

        <PricingCard.Description>{plan.description}</PricingCard.Description>

        <p>
          {plan.discount && (
            <PricingCard.PreviousPrice>${plan.price}</PricingCard.PreviousPrice>
          )}

          <PricingCard.Price>
            ${plan.discount?.priceAfterDiscount ?? plan.price}
          </PricingCard.Price>

          <PricingCard.PriceDetails hasDurationInMonths={hasDurationInMonths}>
            {" "}
            / {monthsDuration} {durationInMonthsPeriod}
          </PricingCard.PriceDetails>
        </p>

        <PricingCard.Disclaimer hasDiscount={hasDiscount}>
          {plan.disclaimer}
        </PricingCard.Disclaimer>

        {plan.discount && (
          <PricingCard.DiscountAlert>
            {jt`After the first ${durationInMonths}month(s), your subscription will change to ${planPrice} every ${monthsDuration}`}
          </PricingCard.DiscountAlert>
        )}

        <PricingCard.Footer className="w-full">
          {isMobile && (
            <Accordion.Root type="single" collapsible className="w-full">
              <Accordion.Item value="features">
                <Accordion.Trigger className="w-full justify-between [&[data-state=open]>svg]:rotate-180">
                  <Label
                    size="sm"
                    htmlFor={`${plan.subscriptionPriceId}-features`}
                    className="cursor-pointer font-bold text-brown-06"
                  >{t`What you need to know`}</Label>

                  <AltArrowDownIcon className="size-4 transition-transform duration-200" />
                </Accordion.Trigger>

                <Accordion.Content className="mt-1">
                  <Markdown
                    components={{
                      ul: (props) => (
                        <PricingCard.FeaturesList
                          id={`${plan.subscriptionPriceId}-features`}
                          {...props}
                        />
                      ),
                    }}
                  >
                    {plan.features.map((feature) => `- ${feature}`).join("\n")}
                  </Markdown>
                </Accordion.Content>
              </Accordion.Item>
            </Accordion.Root>
          )}

          {!isMobile && (
            <>
              <Label
                size="sm"
                htmlFor={`${plan.subscriptionPriceId}-features`}
                className="font-bold text-brown-06"
              >{t`What you need to know`}</Label>

              <Markdown
                components={{
                  ul: (props) => (
                    <PricingCard.FeaturesList
                      id={`${plan.subscriptionPriceId}-features`}
                      {...props}
                    />
                  ),
                }}
              >
                {plan.features.map((feature) => `- ${feature}`).join("\n")}
              </Markdown>
            </>
          )}
        </PricingCard.Footer>
      </PricingCard.Container>
    </PricingCard.Root>
  );
});
SubscriptionItem.displayName = "SubscriptionItem.Item";
