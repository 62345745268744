import { Navigate, useParams } from "react-router-dom";

import { EditSessionActions } from "@/domains/coaching/components";
import { EditSessionContext } from "@/domains/coaching/context";
import { useAdminSession, useEditSessionForm } from "@/domains/coaching/hooks";
import { AdminScreenContentLayout } from "@/domains/coaching/layouts";
import {
  EditSessionForm,
  EditSessionHeader,
} from "@/domains/coaching/sections";
import { ROUTES } from "@/router";
import { Loading } from "@/ui";

export const EditSession = () => {
  const { sessionId } = useParams();

  const {
    data: session,
    isLoading,
    isError,
  } = useAdminSession(Number(sessionId));

  const editForm = useEditSessionForm(session);

  if (!sessionId || isError) {
    return <Navigate to={ROUTES.COACHING.OVERVIEW} />;
  }

  if (isLoading) {
    return (
      <div className="flex size-full items-center justify-center">
        <Loading />
      </div>
    );
  }

  return (
    <AdminScreenContentLayout>
      <EditSessionContext.Provider value={{ sessionForm: editForm }}>
        <EditSessionHeader />

        <div className="grow">
          <EditSessionForm />
        </div>

        <EditSessionActions />
      </EditSessionContext.Provider>
    </AdminScreenContentLayout>
  );
};
