import { t } from "ttag";

import type { Coupon, Plan } from "../models";
import { DISCOUNT_DURATION, DISCOUNT_TYPE, PLAN_DURATION } from "../models";

export const getBillingPeriodText = (
  durationInMonths: Plan["durationInMonths"],
) => {
  return durationInMonths === PLAN_DURATION.MONTHLY.VALUE
    ? t`28 days`
    : t`${durationInMonths} months`;
};

export const getPeriodDurationText = (
  durationInMonths: Plan["durationInMonths"],
) => {
  return durationInMonths === PLAN_DURATION.MONTHLY.VALUE
    ? t`month`
    : t`${durationInMonths} months`;
};

export const getCouponMessageFromResponse = (
  subscriptionPlan: Plan,
  coupon: Coupon,
) => {
  const { price, durationInMonths } = subscriptionPlan;

  const baseText = (() => {
    switch (coupon.duration) {
      case DISCOUNT_DURATION.FOREVER:
        return t`For the duration of your subscription`;
      case DISCOUNT_DURATION.REPEATING:
        return t`For your first ${coupon.durationInMonths} month(s)`;
      default:
        return t`For today`;
    }
  })();
  const billingPeriod = getBillingPeriodText(durationInMonths);

  const discountText = (() => {
    switch (coupon.type) {
      case DISCOUNT_TYPE.PERCENTAGE: {
        const discountedPrice = (
          price -
          (price * coupon.percentOff) / 100
        ).toFixed(2);
        return t`, enjoy ${coupon.percentOff}% off and pay $${discountedPrice}. `;
      }
      case DISCOUNT_TYPE.FIXED_AMOUNT: {
        const discountedPrice = Math.max(price - coupon.amountOff, 0).toFixed(
          2,
        );
        return t`, enjoy $${coupon.amountOff} off and pay $${discountedPrice}. `;
      }
    }
  })();

  const priceText = (() => {
    switch (coupon.duration) {
      case DISCOUNT_DURATION.FOREVER:
        return "";
      case DISCOUNT_DURATION.REPEATING:
        return t`Starting after that, you'll be billed $${price} every ${billingPeriod}.`;
      default:
        return t`After that, you'll be billed the regular price of $${price} every ${billingPeriod}.`;
    }
  })();

  return `${baseText}${discountText}${priceText}`;
};
