import type { SubscriptionStatus } from "@/domains/billing/models";
import type { Consultation, MedicationRequest } from "@/shared.types";
import type { ServiceResponse } from "./api.types";
import { privateAPI } from "./axios";

export const HOME_DOMAIN = "home";

export interface HomeData {
  intakeFormCompleted: boolean;
  refillRequest?: MedicationRequest;
  subscriptionStatus: SubscriptionStatus;
  upcomingConsultations: Consultation[];
}

export const getHomeDataQuery = () => ({
  queryKey: [HOME_DOMAIN, "getHomeDataQuery"],
  queryFn: async () => {
    const { data } = await privateAPI.get<ServiceResponse<HomeData>>(
      `/patients/home-screen`,
    );

    return data.data;
  },
});
