import { Navigate, Route } from "react-router-dom";

import { BASE_ROUTE, ROUTES } from "@/router";
import { PortalRoutesWrapper } from "@/router/components/PortalRoutesWrapper";
import { PORTAL_USER_TYPE } from "@/shared";
import type { DomainRoute } from "@/shared.types";
import { usePortalUserStore } from "@/stores";
import { isInactiveSubscription } from "@/utils";
import { ChangeSubscription, Overview } from "./screens";

const getPartialRoute = (route: DomainRoute) => {
  return route.replace(BASE_ROUTE.BILLING, "");
};

export const BillingRouter = () => {
  const userType = usePortalUserStore((state) => state.portalUser?.type);
  const activeSubscription = usePortalUserStore(
    ({ portalUser }) =>
      portalUser && !isInactiveSubscription(portalUser.subscriptionStatus),
  );

  const canChangeSubscription =
    activeSubscription && userType !== PORTAL_USER_TYPE.COACHING;

  if (userType === PORTAL_USER_TYPE.ADMIN) {
    return <Navigate replace to={ROUTES.BASE} />;
  }

  return (
    <PortalRoutesWrapper>
      <Route index element={<Overview />} />

      {canChangeSubscription && (
        <Route
          element={<ChangeSubscription />}
          path={getPartialRoute(ROUTES.BILLING.CHANGE_SUBSCRIPTION)}
        />
      )}

      <Route path="*" element={<Navigate to={BASE_ROUTE.BILLING} />} />
    </PortalRoutesWrapper>
  );
};
