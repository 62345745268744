import type { QueryClient } from "@tanstack/react-query";

import type { PortalUser } from "@/shared";
import { authStore } from "@/stores";
import type { ServiceResponse } from "./api.types";
import { privateAPI, publicAPI } from "./axios";
import { PORTAL_DOMAIN } from "./portal";

export interface AuthData {
  accessToken: string;
  user: PortalUser;
}

interface LoginRequest {
  email: string;
  password: string;
}

export const login = {
  mutation: async (params: LoginRequest) => {
    const { data } = await publicAPI.post<ServiceResponse<AuthData>>("/login", {
      ...params,
    });

    return data.data;
  },
};

export const logout = (queryClient: QueryClient) => {
  authStore.setToken(null);
  queryClient.removeQueries();
};

export const setPassword = {
  mutation: async ({
    password,
    passwordConfirmation,
    token,
  }: {
    password: string;
    passwordConfirmation: string;
    token?: string | null;
  }) => {
    const { data } = await publicAPI.put<
      ServiceResponse<{ patientId: string }>
    >("/patients/set-password", {
      password,
      password_confirmation: passwordConfirmation,
      token,
    });

    return data.data;
  },
};

export const setFridaysPassword = {
  mutation: async ({
    password,
    passwordConfirmation,
  }: {
    password: string;
    passwordConfirmation: string;
  }) => {
    const { data } = await privateAPI.put<ServiceResponse<void>>(
      "/patients/set-fridays-password",
      {
        password,
        password_confirmation: passwordConfirmation,
      },
    );

    return data.data;
  },
  invalidates: (queryClient: QueryClient) => {
    void queryClient.invalidateQueries({
      queryKey: [PORTAL_DOMAIN],
    });
  },
};

export const forgotPassword = {
  mutation: async ({ email }: { email: string }) => {
    await publicAPI.post("/patients/forgot-password", { email });
  },
};
