import type { PropsWithChildren } from "react";
import type { VariantProps } from "tailwind-variants";
import { tv } from "tailwind-variants";

import { PendingRefillRequestModal } from "@/domains/my-meds/components";
import { ChangePlanFridaysModal, ChangePlanOpenLoopModal } from "@/modals";
import { ScrollArea } from "@/ui";

const layoutVariance = tv({
  slots: {
    base: "bg-salmon-01",
    content: "flex h-full flex-col gap-7 p-6 md:px-16 md:py-12",
  },
  variants: {
    variant: {
      secondary: { base: "bg-brown-02" },
    },
  },
});

type LayoutVariance = VariantProps<typeof layoutVariance>;

interface ScreenLayoutProps extends PropsWithChildren, LayoutVariance {
  className?: string;
}

export const ScreenLayout = ({
  children,
  className,
  variant,
}: ScreenLayoutProps) => {
  const { base, content } = layoutVariance({ variant });
  return (
    <ScrollArea className={base()}>
      <div className={content({ className })}>{children}</div>

      <ChangePlanOpenLoopModal />
      <ChangePlanFridaysModal />
      <PendingRefillRequestModal />
    </ScrollArea>
  );
};
