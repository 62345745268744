import { t } from "ttag";

import { useScheduleConsultationContext } from "@/contexts";
import { useProvidersQuery } from "@/hooks";
import { PROVIDER_PREFERENCE } from "@/shared.constants";
import type { Provider } from "@/shared.types";
import {
  CardPrimitive as Card,
  Combobox,
  Loader,
  MagnifierIcon,
  UserIcon,
} from "@/ui";

export const ProviderPreference = () => {
  const {
    providerPreference,
    provider,
    actions: { setProviderPreference, setProvider, setScheduleDate },
  } = useScheduleConsultationContext();

  const { data, isLoading } = useProvidersQuery();

  const providerOptions = data?.map((provider) => ({
    value: provider.id,
    label: provider.fullName,
    avatarUrl: provider.avatarUrl,
  }));

  const handleSelectPreference = (preference: typeof providerPreference) => {
    setProviderPreference(preference);
    setScheduleDate((prev) => ({ ...prev, timeSlot: null }));

    if (preference === PROVIDER_PREFERENCE.EARLIEST) {
      setProvider();
    } else {
      setScheduleDate((prev) => ({ ...prev, appointmentDate: null }));
    }
  };

  const handleSelectedProvider = (id?: Provider["id"]) => {
    const selectedProvider = data?.find((provider) => provider.id === id);
    setProvider(selectedProvider);
    setScheduleDate((prev) => ({
      ...prev,
      appointmentDate: null,
      timeSlot: null,
    }));
  };

  return (
    <Card.Root className="gap-6 bg-brown-01">
      <Card.Title>{t`2. Provider preference`}</Card.Title>
      <Card.Content className="flex flex-col items-center gap-x-3 gap-y-6 lg:flex-row">
        <Combobox
          label={t`Provider preference`}
          id="provider-preference-select"
          value={providerPreference}
          onChange={handleSelectPreference}
          options={[
            {
              value: PROVIDER_PREFERENCE.EARLIEST,
              label: t`Earliest available`,
            },
            {
              value: PROVIDER_PREFERENCE.CUSTOM,
              label: t`Choose a provider`,
            },
          ]}
          left={<UserIcon />}
          containerClassName="basis-1/2 w-full"
        />

        {providerPreference === PROVIDER_PREFERENCE.CUSTOM && (
          <Combobox
            value={provider?.id ?? ""}
            onChange={handleSelectedProvider}
            options={providerOptions ?? []}
            label={t`Select provider`}
            placeholder={
              isLoading ? t`Loading providers...` : t`Select a provider`
            }
            id="provider-select"
            autocomplete
            left={isLoading ? <Loader /> : <MagnifierIcon />}
            containerClassName="w-full basis-1/2"
            disabled={isLoading}
          />
        )}
      </Card.Content>
    </Card.Root>
  );
};
