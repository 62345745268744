import { useMutation, useQueryClient } from "@tanstack/react-query";
import { t } from "ttag";

import { errorToast, useToastStore } from "@/ui";
import { coachingQueriesStore, subscribeToSession } from "../api";

export const useSubscribeToSession = () => {
  const pushToast = useToastStore((state) => state.pushToast);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: subscribeToSession,
    onSuccess: (session) => {
      const sessionTitle = session.title
        ? t`You're all set for ${session.title}`
        : "";

      void pushToast({
        type: "success",
        title: t`Success`,
        message: t`Booking Confirmed! ${sessionTitle}`,
      });

      void queryClient.invalidateQueries({
        queryKey: coachingQueriesStore.coaching.patient._ctx.session.queryKey,
      });
    },
    onError: errorToast,
  });
};
