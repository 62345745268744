import { tv } from "tailwind-variants";

export const selectVariants = tv({
  slots: {
    trigger:
      "data-[state=open]:border-border-brown-08 flex w-full items-center gap-2 rounded-lg border border-brown-05 bg-salmon-01 px-4 py-3 text-sm text-brown-09 placeholder:text-brown-06 focus:border-brown-08 focus:outline-none focus:ring-1 focus:ring-salmon-03 disabled:cursor-not-allowed disabled:border-brown-02 disabled:opacity-70 aria-[invalid=true]:border-red-07 data-[placeholder]:text-opacity-50 [&>span]:line-clamp-1 [&>svg]:data-[state=open]:rotate-180",
    scrollButton: "flex cursor-default items-center justify-center py-1",
    content:
      "relative z-50 max-h-96 min-w-[var(--radix-popover-trigger-width)] overflow-hidden rounded-md border bg-salmon-01 text-brown-09 shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
    viewport: "p-1",
    label: "px-2 py-1.5 text-sm font-semibold",
    item: "relative flex w-full cursor-pointer select-none items-center justify-between gap-2 truncate rounded-sm px-4 py-3 pl-2 pr-8 text-sm outline-none hover:bg-gray-50 focus:bg-gray-50 aria-selected:bg-gray-50 aria-selected:text-brown-10 data-[disabled]:pointer-events-none data-[disabled]:opacity-50 [&:last-child]:rounded-md [&:not(:last-child)]:border-b",
    itemSelectedIcon: "size-6 self-end stroke-2",
    separator: "-mx-1 my-1 h-px",
  },
  variants: {
    position: {
      popper: {
        content:
          "data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1",
        viewport:
          "h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]",
      },
      "item-aligned": {
        content: "",
        viewport: "",
      },
    },
  },
});
