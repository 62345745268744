import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

import { ScreenLayout } from "@/layouts";
import { Form } from "@/ui";
import {
  ChangeSubscriptionContent,
  ChangeSubscriptionFooter,
  ChangeSubscriptionHeader,
} from "./sections";
import { getSubscriptionSchema } from "./shared";

export const ChangeSubscription = () => {
  const form = useForm({
    resolver: zodResolver(getSubscriptionSchema()),
  });

  return (
    <ScreenLayout className="lg:gap-1">
      <ChangeSubscriptionHeader />

      <Form.Provider {...form}>
        <div className="flex flex-col gap-6 lg:gap-0">
          <ChangeSubscriptionContent />

          <ChangeSubscriptionFooter />
        </div>
      </Form.Provider>
    </ScreenLayout>
  );
};
