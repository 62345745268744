import { Link } from "react-router-dom";
import { t } from "ttag";

import { FullScreenLoader } from "@/components";
import { ROUTES } from "@/router";
import { Button, CheckCircleIcon, DisketteIcon, TrashBinIcon } from "@/ui";
import { handleAxiosFieldErrors } from "@/utils";
import { useCreateSessionContext } from "../context";
import { useCreateSession } from "../hooks";
import type { ActionsVariants } from "./utils";
import { actionsVariants } from "./utils";

export const CreateSessionActions = ({ position }: ActionsVariants) => {
  const { container } = actionsVariants({ position });

  const {
    sessionForm: {
      formState: { dirtyFields },
      handleSubmit,
      setError,
    },
  } = useCreateSessionContext();

  const createSessionMutation = useCreateSession(setError);

  const handleSaveDraft = handleSubmit((value) =>
    createSessionMutation.mutate({ ...value, published: false }),
  );

  const handlePublish = handleSubmit((value) =>
    createSessionMutation.mutate(
      { ...value, published: true },
      { onError: (error) => handleAxiosFieldErrors(error, setError) },
    ),
  );

  const isDirty = Object.keys(dirtyFields).length > 0;

  return (
    <div className={container()}>
      <Button
        asChild
        size="lg"
        variant="tertiary"
        className="px-2 text-red-08 hover:text-red-07"
      >
        <Link to={ROUTES.COACHING.OVERVIEW}>
          <TrashBinIcon className="size-4" />
          {t`Discard`}
        </Link>
      </Button>

      <Button
        onClick={handleSaveDraft}
        disabled={!isDirty}
        variant="secondary"
        size="lg"
      >
        <DisketteIcon className="size-4" />
        {t`Save as draft`}
      </Button>

      <Button onClick={handlePublish} disabled={!isDirty} size="lg">
        <CheckCircleIcon className="size-4" />
        {t`Publish`}
      </Button>

      {createSessionMutation.isPending && <FullScreenLoader />}
    </div>
  );
};
