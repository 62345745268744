import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import { addMinutes, format } from "date-fns";
import { t } from "ttag";

import { FullScreenLoader } from "@/components";
import type { ModalProps } from "@/shared.types";
import { Button, CardPrimitive, Dialog, StarsIcon } from "@/ui";
import { useSubscribeToSession } from "../hooks";
import type { Session } from "../models";

type SubscribeSessionModalProps = ModalProps & {
  session?: Session;
};

export const SubscribeSessionModal = ({
  show,
  onClose,
  session,
}: SubscribeSessionModalProps) => {
  const subscribeToSessionMutation = useSubscribeToSession();

  const handleSaveSpot = () => {
    if (session) {
      subscribeToSessionMutation.mutate(session.id, {
        onSuccess: onClose,
      });
    }
  };

  return (
    <Dialog.Root open={show} onOpenChange={onClose}>
      {session && (
        <Dialog.Content size="modal" className="h-fit">
          <Dialog.Header className="pb-8 md:pt-4">
            <Dialog.Title>{t`Save your spot!`}</Dialog.Title>
            <VisuallyHidden asChild>
              <Dialog.Description>
                {t`By clicking the "Book my Spot" button, you are reserving your place for this session.`}
              </Dialog.Description>
            </VisuallyHidden>
          </Dialog.Header>

          <CardPrimitive.Root className="mb-6 gap-1.5 bg-brown-02 p-8 text-brown-10">
            <div className="absolute left-0 top-0 flex size-10 -translate-x-1/4 -translate-y-1/4 items-center justify-center rounded-full bg-nature-03">
              <StarsIcon className="size-4 text-nature-10" />
            </div>
            <CardPrimitive.Title>{session.title}</CardPrimitive.Title>
            <CardPrimitive.Description className="text-base font-medium">
              {format(session.startAt, "MM/dd/yy")}
            </CardPrimitive.Description>
            <CardPrimitive.Description className="text-base lowercase text-brown-09">
              {`${format(session.startAt, "HH:mma")} - ${format(addMinutes(session.startAt, session.duration), "HH:mma")} `}
            </CardPrimitive.Description>
          </CardPrimitive.Root>

          <Dialog.Footer variant="sticky">
            <Button size="lg" onClick={handleSaveSpot}>
              {t`Book my Spot`}
            </Button>
          </Dialog.Footer>

          {subscribeToSessionMutation.isPending && <FullScreenLoader />}
        </Dialog.Content>
      )}
    </Dialog.Root>
  );
};
