import type { ServiceResponse } from "@/api";
import { privateAPI, publicAPI } from "@/api";
import type { PasswordFormValues, Patient } from "@/shared.types";
import { formatDOB } from "@/utils";
import type { MainInfo } from "../models";

interface SetHealthiePasswordParams extends PasswordFormValues {
  token: string;
}

export const setEmbeddablesPassword = async ({
  password,
  passwordConfirmation,
  token,
}: SetHealthiePasswordParams) => {
  const { data } = await publicAPI.put<
    ServiceResponse<{ accessToken: string }>
  >("/embeddables/set-password", {
    password,
    password_confirmation: passwordConfirmation,
    token,
  });

  return data.data;
};

export const updateEmbeddablesPatient = async (params: MainInfo) => {
  const { data } = await privateAPI.put<ServiceResponse<Patient>>(
    "/embeddables/main-info",
    {
      dateOfBirth: formatDOB(params.dateOfBirth),
      location: params.location,
    },
  );

  return data.data;
};
