import { BrowserRouter, Route, Routes } from "react-router-dom";

import { LoadingScreen } from "@/components";
import {
  BillingRouter,
  CoachingRouter,
  EMBEDDABLES_ONBOARDING_ROUTE,
  EmbeddablesOnboardingRouter,
  GuestRouter,
  OnboardingRouter,
} from "@/domains";
import { PORTAL_USER_TYPE } from "@/shared";
import { useAuthStore, usePortalUserStore } from "@/stores";
import { isInactiveSubscription } from "@/utils";
import { BaseRouter } from "./components";
import { CoachingUserRouter } from "./components/CoachingUserRouter";
import { PatientUserRouter } from "./components/PatientUserRouter";
import { BASE_ROUTE } from "./constants";
import { useSetPortalUser } from "./hooks";

export const Router = () => {
  const { isLoading } = useSetPortalUser();
  const isLoggedOut = useAuthStore((state) => !state.token);
  const userType = usePortalUserStore((state) => state.portalUser?.type);
  const isCancelled = usePortalUserStore(
    ({ portalUser }) =>
      portalUser && isInactiveSubscription(portalUser.subscriptionStatus),
  );

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<BaseRouter />} />

        {!isCancelled && (
          <Route
            path={`${EMBEDDABLES_ONBOARDING_ROUTE.BASE}/*`}
            element={<EmbeddablesOnboardingRouter />}
          />
        )}

        <Route
          path={`${BASE_ROUTE.ONBOARDING}/*`}
          element={<OnboardingRouter />}
        />

        <Route path={`${BASE_ROUTE.COACHING}/*`} element={<CoachingRouter />} />

        <Route path={`${BASE_ROUTE.BILLING}/*`} element={<BillingRouter />} />

        {isLoggedOut && <Route path="*" element={<GuestRouter />} />}

        {userType === PORTAL_USER_TYPE.PATIENT && (
          <Route path="*" element={<PatientUserRouter />} />
        )}

        {userType === PORTAL_USER_TYPE.COACHING && (
          <Route path="*" element={<CoachingUserRouter />} />
        )}

        <Route path="*" element={<BaseRouter />} />
      </Routes>
    </BrowserRouter>
  );
};
