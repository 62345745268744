import type { SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { t } from "ttag";

import { FullScreenLoader } from "@/components";
import { useEmbeddablesForms } from "@/domains/embeddables-onboarding/context";
import type { MainInfo } from "@/domains/embeddables-onboarding/models";
import { EMBEDDABLES_ONBOARDING_ROUTE } from "@/domains/embeddables-onboarding/router";
import { useBoolean } from "@/hooks";
import { LANGUAGE_OPTIONS } from "@/shared.options";
import {
  AltArrowRightIcon,
  Button,
  Combobox,
  DateOfBirthInputs,
  Form,
  TranslationIcon,
} from "@/ui";
import { calculateAgeByDOB, handleAxiosFieldErrors, isValidAge } from "@/utils";
import { AgeDQScreenAlert } from "../AgeDQScreenAlert";
import { LocationFormSection } from "./components";
import { useMainInfoForm, useUpdatePatientMutation } from "./hooks";

export const MainInfoForm = () => {
  const {
    value: showAgeDQAlert,
    setTrue: openAgeDQAlert,
    setFalse: closeAgeDQAlert,
  } = useBoolean(false);

  const {
    actions: { setMainInfoFormValues },
  } = useEmbeddablesForms();

  const form = useMainInfoForm();

  const navigate = useNavigate();

  const updateEmbeddablesPatient = useUpdatePatientMutation();

  const onSubmit: SubmitHandler<MainInfo> = (values) => {
    setMainInfoFormValues(values);

    if (!isValidAge(calculateAgeByDOB(values.dateOfBirth))) {
      return openAgeDQAlert();
    }

    updateEmbeddablesPatient.mutate(values, {
      onSuccess: () =>
        navigate(EMBEDDABLES_ONBOARDING_ROUTE.MAIN_INFO_MEASUREMENTS),
      onError: (error) => handleAxiosFieldErrors(error, form.setError),
    });
  };

  if (showAgeDQAlert) {
    return <AgeDQScreenAlert onGoBack={closeAgeDQAlert} />;
  }

  return (
    <Form.Provider {...form}>
      {updateEmbeddablesPatient.isPending && <FullScreenLoader />}
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex grow flex-col gap-8 md:px-14"
      >
        <div className="grid gap-x-4 gap-y-1.5 pb-0 md:grid-cols-2 md:p-4">
          <Form.Field
            control={form.control}
            name="dateOfBirth"
            render={({ formState: { errors } }) => (
              <Form.Item>
                <Form.Label>{t`Date of birth *`}</Form.Label>
                <Form.Control>
                  <DateOfBirthInputs register={form.register} />
                </Form.Control>
                <Form.Message compact={false}>
                  {errors.dateOfBirth?.month?.message ??
                    errors.dateOfBirth?.day?.message ??
                    errors.dateOfBirth?.year?.message}
                </Form.Message>
              </Form.Item>
            )}
          />

          <Form.Field
            control={form.control}
            name="preferredLanguage"
            render={({ field }) => (
              <Form.Item>
                <Form.Label>{t`Language of preference *`}</Form.Label>
                <Form.Control>
                  <Combobox
                    placeholder={t`E.g. English`}
                    left={<TranslationIcon />}
                    options={LANGUAGE_OPTIONS}
                    {...field}
                  />
                </Form.Control>
                <Form.Message compact={false} />
              </Form.Item>
            )}
          />
        </div>

        <LocationFormSection />

        <div className="mt-auto flex justify-end">
          <Button type="submit" size="lg" className="w-full sm:w-fit">
            {t`Next`}
            <AltArrowRightIcon className="hidden size-4 sm:block" />
          </Button>
        </div>
      </form>
    </Form.Provider>
  );
};
