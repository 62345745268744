import { Navigate, Route } from "react-router-dom";

import { BASE_ROUTE, ROUTES } from "@/router";
import { PortalRoutesWrapper } from "@/router/components/PortalRoutesWrapper";
import { PORTAL_USER_TYPE } from "@/shared";
import type { DomainRoute } from "@/shared.types";
import { usePortalUserStore } from "@/stores";
import { isInactiveSubscription } from "@/utils";
import {
  CoachingHub,
  CoachingLab,
  CreateSession,
  EditSession,
  PastSessions,
} from "../screens";

const getPartialRoute = (route: DomainRoute) => {
  return route.replace(BASE_ROUTE.COACHING, "");
};

export const CoachingRouter = () => {
  const userType = usePortalUserStore((state) => state.portalUser?.type);

  const activeSubscription = usePortalUserStore(
    ({ portalUser }) =>
      portalUser && !isInactiveSubscription(portalUser.subscriptionStatus),
  );

  if (!activeSubscription) {
    return <Navigate to={ROUTES.BASE} />;
  }

  return (
    <PortalRoutesWrapper>
      {userType === PORTAL_USER_TYPE.ADMIN ? (
        <>
          <Route index element={<CoachingLab />} />
          <Route
            path={getPartialRoute(ROUTES.COACHING.CREATE_SESSION)}
            element={<CreateSession />}
          />
          <Route
            path={getPartialRoute(ROUTES.COACHING.EDIT_SESSION)}
            element={<EditSession />}
          />
        </>
      ) : (
        <>
          <Route
            path={getPartialRoute(ROUTES.COACHING.OVERVIEW)}
            element={<CoachingHub />}
          />

          <Route
            path={`${getPartialRoute(ROUTES.COACHING.PAST_SESSIONS)}/:sessionType`}
            element={<PastSessions />}
          />
        </>
      )}

      <Route path="*" element={<Navigate to={BASE_ROUTE.COACHING} />} />
    </PortalRoutesWrapper>
  );
};
