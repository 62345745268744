import type { ComponentPropsWithoutRef } from "react";
import { isValid } from "date-fns";
import type { Control } from "react-hook-form";
import { t } from "ttag";

import { NOW } from "@/shared.constants";
import {
  createDateFromTime,
  extractTimeFromDate,
  generateTimeIntervalOptions,
} from "@/shared/utils";
import { Form } from "@/ui";
import { Select } from "@/ui/form/Select";
import type { Session } from "../models";

const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const options = generateTimeIntervalOptions();

type SessionStartTimeControl<T> =
  T extends Pick<Session, "startAt"> ? Control<T, "startAt"> : never;

type SessionStartTimeFieldProps<T extends Pick<Session, "startAt">> =
  ComponentPropsWithoutRef<typeof Form.Item> & {
    control: SessionStartTimeControl<T>;
  };

export const SessionStartTimeField = <T extends Pick<Session, "startAt">>({
  control,
  ...props
}: SessionStartTimeFieldProps<T>) => {
  return (
    <Form.Field
      control={control}
      name="startAt"
      render={({ field }) => {
        const value = isValid(field.value) ? field.value : undefined;

        return (
          <Form.Item {...props}>
            <Form.Label aria-required>
              {t`Time`} ({localTimeZone.replace("_", " ")})
            </Form.Label>
            <Form.Control>
              <Select
                options={options}
                {...field}
                value={value && extractTimeFromDate(value)}
                onChange={(time) => {
                  const baseDate = value ?? NOW;
                  const updatedDate = createDateFromTime(time, baseDate);
                  field.onChange(updatedDate);
                }}
              />
            </Form.Control>
            <Form.Message compact={false} />
          </Form.Item>
        );
      }}
    />
  );
};
